import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { EChartsOption } from 'echarts';
import moment from "moment";

export interface RejectedByUniversity {
    count: number;
    percentage: number;
}

export interface UniversityStatusAttributes {
    rejected_by_university: RejectedByUniversity;
    agent_confirmed_to_discontinue: RejectedByUniversity;
    agent_non_responsive: RejectedByUniversity;
    not_eligible: RejectedByUniversity;
}

export interface UniversityStatusData {
    attributes: UniversityStatusAttributes;
}

export interface UniversityStatusState {
    data: UniversityStatusData;
}

interface University {
    university_name: string;
    location: string;
    country: string;
    applications_received: number;
    days_after_application: number | null;
    comment_ratio: number;
  }
  
  interface ApplicationData {
    universities: University[];
  }
  
  interface UniversityData {
    data: ApplicationData;
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userDetails: {
        attributes: {
            first_name: string;
            role: string;
            image: {
                url: string;
            },
        },
    },
    toggleDrawer: boolean,
    applicationStatus: EChartsOption;
    refundStatus: EChartsOption;
    applicationStagesProcess: EChartsOption;
    globalFilterYear: string[],
    globalFilterIntakes: string[],
    globalFilterCountry: string[],
    applicationFilterYear: string,
    applicationFilterCountry: string,
    refundFilterYear: string,
    refundFilterCountry: string,
    refundFilterIntake: string,
    applicationInProcessFilterYear: string,
    applicationInProcessFilterCountry: string,
    applicationInProcessFilterIntake: string,
    discontinuedFilterYear: string,
    discontinuedFilterCountry: string,
    discontinuedFilterIntake: string,
    discontinuedData:UniversityStatusState,
    universityData:UniversityData[],
    order: "asc" | "desc";
    orderBy: string,
    startDate: Date | null,
    endDate: Date | null,
    formattedStartDate:string,
    formattedEndDate:string,
    universityFilterUniverSity: string,
    universityFilterCountry: string,
    globalFilterUniversity: string[],
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class VisualAnalyticController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getProfileAPICallId: string = ""
    getFilterYearAPICallId: string = ""
    getFilterIntakesAPICallId: string = ""
    getFilterCountryAPICallId: string = ""
    getApplicationStatusDetailsAPICallId: string = ""
    getRefundStagesDetailsAPICallId: string = ""
    getApplicationInProgressAPICallId: string = ""
    getDiscontinuedAPICallId: string = ""
    getAdmissionPerformanceAPICallId: string = ""
    getAllUniversitiesAPICallId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            userDetails: {
                attributes: {
                    first_name: '',
                    role: '',
                    image: {
                        url: '',
                    },
                },
            },
            toggleDrawer: false,
            applicationStatus: {
                legend: {},
                tooltip: {},
                dataset: {
                    source: []
                },
                xAxis: { type: 'category' },
                yAxis: {},
                series: [{
                    type: 'bar', itemStyle: {
                        color: '#F59E0B'
                    }
                }, {
                    type: 'bar', itemStyle: {
                        color: '#34D399'
                    }
                }, {
                    type: 'bar', itemStyle: {
                        color: '#497AF9'
                    }
                }, {
                    type: 'bar', itemStyle: {
                        color: '#94A3B8B2'
                    }
                }],
                barCategoryGap: '40%',
                barGap: '3%'
            },
            refundStatus:{
                tooltip: {
                    trigger: 'item',
                  },
                  legend: {
                    top: '5%',
                    left: 'center',
                  },
                  series: [
                    {
                      name: 'Refund stages',
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      color: ['#E5ECFF', '#CCE3FF', '#99C2FF', '#669AFF'],
                      padAngle: 2,
                      itemStyle: {
                        borderRadius: 10,
                      },
                      label: {
                        show: false,
                        position: 'center',
                      },
                      emphasis: {},
                      labelLine: {
                        show: false,
                      },
                      data: [],
                    },
                  ],
            },
            applicationStagesProcess:{
                tooltip: {
                    trigger: 'axis',
                  },
                  legend: {
                    data: ['Application Progress'],
                    top: '5%',
                    left: 'center',
                  },
                  xAxis: {
                    type: 'category',
                    data: [
                      'Application Fees Paid', 'Application Received', 'Submitted to Institution', 
                      'Conditional Offer', 'Unconditional Offer', 'Tuition Fees Paid', 
                      'Pre-CAS/LOA Stage', 'CAS/LOA Applied', 'CAS/LOA Received', 
                      'Visa Applied', 'Visa Received'
                    ],
                    axisLabel: {
                      rotate: 25,
                      interval: 0,
                    },
                  },
                  yAxis: {
                    type: 'value',
                  },
                  series: [
                    {
                     
                      data: [],
                      type: 'line',
                      itemStyle: {
                        color: '#F59E0B',
                      },
                      lineStyle: {
                        width: 3,
                      },
                    }
                  ]
            },
            globalFilterYear: [],
            globalFilterIntakes: [],
            globalFilterCountry: [],
            applicationFilterYear: "",
            applicationFilterCountry: "",
            refundFilterYear: "",
            refundFilterCountry: "",
            refundFilterIntake: "",
            applicationInProcessFilterYear: "",
            applicationInProcessFilterCountry: "",
            applicationInProcessFilterIntake: "",
            discontinuedFilterYear: "",
            discontinuedFilterCountry: "",
            discontinuedFilterIntake: "",
            discontinuedData:{
                data: {
                  attributes: {
                    rejected_by_university: {
                      count: 0,
                      percentage: 0,
                    },
                    agent_confirmed_to_discontinue: {
                      count: 0,
                      percentage: 0,
                    },
                    agent_non_responsive: {
                      count: 0,
                      percentage: 0,
                    },
                    not_eligible: {
                      count: 0,
                      percentage: 0,
                    },
                  },
                },
              },
            universityData:[],
            order: "asc",
            orderBy: "",
            startDate: null,
            endDate: null,
            formattedStartDate: "",
            formattedEndDate: "",
            universityFilterUniverSity: "",
            universityFilterCountry: "",
            globalFilterUniversity:[]
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        await this.getFormApprovalProfile()
        await this.getFilterYear()
        await this.getFilterIntakes()
        await this.getFilterCountry()
        await this.getApplicationStatusDetails()
        await this.getRefundStagesDetails()
        await this.getApplicationInProgressDetails()
        await this.getDiscontinuedStagesDetails()
        await this.getAdmissionPerformanceDetails()
        await this.getFilterUniversity()
        // Customizable Area End
        super.componentDidMount();
        this.getToken();

    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };


    onHomeClick = (pageName: string) => {
        setStorageData("LandingPageActive", pageName)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPage"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    handleToggle = () => {
        this.setState({
            toggleDrawer: !this.state.toggleDrawer
        });
    };

    navigationToAnyPage = (pageName: string) => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Received", message);
        const messageId = message.id;
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        const responseCallbacks = {
            [this.getProfileAPICallId]: this.getProfileFormSuccessCallBack,
            [this.getFilterYearAPICallId]: this.getFilterYearSuccessCallBack,
            [this.getFilterIntakesAPICallId]: this.getFilterIntakesSuccessCallBack,
            [this.getFilterCountryAPICallId]: this.getFilterCountrySuccessCallBack,
            [this.getApplicationStatusDetailsAPICallId]: this.getApplicationStatusDetailsSuccessCallBack,
            [this.getRefundStagesDetailsAPICallId]: this.getRefundStagesDetailsSuccessCallBack,
            [this.getApplicationInProgressAPICallId]: this.getApplicationInProgressSuccessCallBack,
            [this.getDiscontinuedAPICallId]: this.getDiscontinuedStagesSuccessCallBack,
            [this.getAdmissionPerformanceAPICallId]: this.getAdmissionPerformanceSuccessCallBack,
            [this.getAllUniversitiesAPICallId]: this.getFilterUniversitySuccessCallBack
        };

        if (getName(MessageEnum.RestAPIResponceMessage) === messageId) {
            const callback = responseCallbacks[responseData];
            if (callback) {
                callback(message);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    apiCall = async (apiData: any) => {
        const { contentType, method, endPoint, body, type } = apiData;

        let token = await getStorageData("token");

        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        body && type !== "formData"
            ? requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    getFormApprovalProfile = async () => {
        let userDetails = await getStorageData("userDetails")
        this.getProfileAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.getProfileEndPoint + userDetails
        });
    }

    getProfileFormSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ userDetails: responseJson.data });
    };

    getFilterYear = async () => {
        this.getFilterYearAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.filterYear
        });
    }

    getFilterYearSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ globalFilterYear: responseJson.data.map((item: number) => item.toString()) });
    };

    getFilterIntakes = async () => {
        this.getFilterIntakesAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.filterIntake
        });
    }

    getFilterIntakesSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ globalFilterIntakes: responseJson.data });
    };

    getFilterCountry = async () => {
        this.getFilterCountryAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.filterCountry
        });
    }

    getFilterCountrySuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ globalFilterCountry: responseJson.data });
    };

    handleApplicationYearChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ applicationFilterYear: event.target.value as string }, () => this.getApplicationStatusDetails());
    };

    handleApplicationCountryChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ applicationFilterCountry: event.target.value as string }, () => this.getApplicationStatusDetails());
    };

    handleRefundYearChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ refundFilterYear:event.target.value as string }, () => this.getRefundStagesDetails());
    };

    handleRefundCountryChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ refundFilterCountry: event.target.value as string }, () => this.getRefundStagesDetails());
    };

    handleRefundIntakeChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ refundFilterIntake: event.target.value as string}, () => this.getRefundStagesDetails());
    };

    getApplicationStatusDetails = async () => {
        const baseURL = `${configJSON.application_status_report}?year=${this.state.applicationFilterYear}&country=${this.state.applicationFilterCountry}`
        this.getApplicationStatusDetailsAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: baseURL
        });
    }

    getApplicationStatusDetailsSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        const transformDatasetSource = (apiData: any) => [
            ['Status', 'In process', 'Enrolled', 'Refunded', 'Discontinued'],
            ['Winter (January March)', apiData.jan_apr.in_progress, apiData.jan_apr.enrolled, apiData.jan_apr.refund, apiData.jan_apr.discontinued],
            ['Summer (April-July)', apiData.may_aug.in_progress, apiData.may_aug.enrolled, apiData.may_aug.refund, apiData.may_aug.discontinued],
            ['Fall (August-December)', apiData.sep_dec.in_progress, apiData.sep_dec.enrolled, apiData.sep_dec.refund, apiData.sep_dec.discontinued],
        ];

        const datasetSource = transformDatasetSource(responseJson.data);

        this.setState((prevState) => ({
            applicationStatus: {
                ...prevState.applicationStatus,
                dataset: {
                    ...prevState.applicationStatus.dataset,
                    source: datasetSource,
                },
            },
        }));
    };

    getRefundStagesDetails = async () => {
        const baseURL = `${configJSON.refund_stage_report}?year=${this.state.refundFilterYear}&country=${this.state.refundFilterCountry}&intake=${this.state.refundFilterIntake}`
        this.getRefundStagesDetailsAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: baseURL
        });
    }

    getRefundStagesDetailsSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const updatedSeries: EChartsOption = {
            tooltip: {
                trigger: 'item',
            },
            legend: {
                top: '5%',
                left: 'center',
            },
            series: [
                {
                    name: 'Refund stages',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    color: ['#E5ECFF', '#CCE3FF', '#99C2FF', '#669AFF'],
                    padAngle: 2,
                    itemStyle: {
                        borderRadius: 10,
                    },
                    label: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {},
                    labelLine: {
                        show: false,
                    },
                    data: [
                        { value: responseJson.data.refund_initiated, name: 'Refund initiated' },
                        { value: responseJson.data.refund_received, name: 'Received' },
                        { value: responseJson.data.refund_submitted_to_institution, name: 'Submitted to institution' },
                        { value: responseJson.data.refund_rejected, name: 'Rejected' },
                    ],
                },
            ],
        }

        this.setState({refundStatus: updatedSeries});
    };

    handleApplicationInProcessYearChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ applicationInProcessFilterYear: event.target.value as string }, () => this.getApplicationInProgressDetails());
    };

    handleApplicationInProcessCountryChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ applicationInProcessFilterCountry: event.target.value as string }, () => this.getApplicationInProgressDetails());
    };

    handleApplicationInProcessIntakeChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ applicationInProcessFilterIntake: event.target.value as string}, () => this.getApplicationInProgressDetails());
    };
    
    getApplicationInProgressDetails = async () => {
        const baseURL = `${configJSON.in_progress_report}?year=${this.state.applicationInProcessFilterYear}&country=${this.state.applicationInProcessFilterCountry}&intake=${this.state.applicationInProcessFilterIntake}`
        this.getApplicationInProgressAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: baseURL
        });
    }

    getApplicationInProgressSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const updatedData = [
            responseJson.data.application_fees_paid,
            responseJson.data.application_received,
            responseJson.data.submitted_to_institution,
            responseJson.data.conditional_offer,
            responseJson.data.unconditional_offer,
            responseJson.data.tuition_fees_paid,
            responseJson.data.pre_cas_loa_stage,
            responseJson.data.cas_loa_applied,
            responseJson.data.cas_loa_received,
            responseJson.data.visa_applied,
            responseJson.data.visa_received
          ];


          this.setState(
            (prevState:any) => ({
              applicationStagesProcess: {
                ...prevState.applicationStagesProcess,
                series: [
                  {
                    ...prevState.applicationStagesProcess.series[0],
                    data: updatedData,
                  }
                ]
              }
            })
          );
    };

    discontinuedYearChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ discontinuedFilterYear: event.target.value as string }, () => this.getDiscontinuedStagesDetailsFilter());
    };

    discontinuedCountryChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ discontinuedFilterCountry: event.target.value as string }, () => this.getDiscontinuedStagesDetailsFilter());
    };

    discontinuedIntakeChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ discontinuedFilterIntake: event.target.value as string}, () => this.getDiscontinuedStagesDetailsFilter());
    };

    getDiscontinuedStagesDetailsFilter = async () => {
        const baseURL = `${configJSON.statistics}?year=${this.state.discontinuedFilterYear}&country=${this.state.discontinuedFilterCountry}&intake=${this.state.discontinuedFilterIntake}`
        this.getDiscontinuedAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: baseURL
        });
    }

    getDiscontinuedStagesDetails = async () => {
        this.getDiscontinuedAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.discontinued_stages
        });
    }

    getDiscontinuedStagesSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({discontinuedData: responseJson})
    };

    getAdmissionPerformanceDetails = async () => {
        this.getAdmissionPerformanceAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.admission_performance
        });
    }

    getAdmissionPerformanceSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({universityData: responseJson.data.universities})
    };


    handleRequestSort = (property: string) => {
        const orderBy = property;
        let order: "asc" | "desc" = "asc";
        if (this.state.orderBy === property && this.state.order === "asc") {
            order = "desc";
        }
        this.setState({ orderBy, order });
    };

    sortData = (data: any, orderBy: string, order: string) => {
        console.log("aValue ", data)
        return [...data].sort((sortingA, sortingB) => {
            const aValue = sortingA[orderBy];
            const bValue = sortingB[orderBy];
            
            if (typeof aValue === "string" && typeof bValue === "string") {
                return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else if (typeof aValue === "number" && typeof bValue === "number") {
                return order === "asc" ? aValue - bValue : bValue - aValue;
            }
            return 0;
        });
    }

    handleDateChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        const startDateFormatted = start ? moment(start).format('YYYY-MM-DD') : "";
        const endDateFormatted = end ? moment(end).format('YYYY-MM-DD') : "";

        this.setState({
            startDate: start,
            endDate: end,
            formattedStartDate: startDateFormatted,
            formattedEndDate: endDateFormatted
        }, () => this.getAdmissionPerformanceDetailsFilter());
    };

    getFilterUniversity = async () => {
        this.getAllUniversitiesAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.select_university
        });
    }

    getFilterUniversitySuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ globalFilterUniversity: responseJson.data.map((item: number) => item.toString()) });
    };

    universityChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ universityFilterUniverSity: event.target.value as string }, () => this.getAdmissionPerformanceDetailsFilter());
    };

    universityCountryChange = (event: React.ChangeEvent<{
        name?: string; value: unknown;
    }>) => {
        this.setState({ universityFilterCountry: event.target.value as string }, () => this.getAdmissionPerformanceDetailsFilter());
    };


    getAdmissionPerformanceDetailsFilter = async () => {
        const formatDate = (date: string) => date ? moment(date).format('DD/MM/YYYY') : null;
  
        const start = formatDate(this.state.startDate as unknown as string);
        const end = formatDate(this.state.endDate as unknown as string);

        const baseURL = `${configJSON.admission_performance_filter}?country=${this.state.universityFilterCountry}&university=${this.state.universityFilterUniverSity}&start_date=${start}&end_date=${end}`
        this.getAdmissionPerformanceAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: baseURL
        });
    }

    // Customizable Area End
}
