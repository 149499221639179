// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { Country, State } from "country-state-city";
import {
  CountryInterfaceData
} from "../../../../packages/blocks/contactus/src/ContactusController";
import { toast } from "react-toastify";

type LogoType = string | null | undefined;
export interface OtherService {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    logo: LogoType;
    service_provider_name: string | null
  }
}

export interface OtherServiceData {
  data: OtherService[];
}

export interface SuccessMessageResponse {
  request_id: string;
  message: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  isAdmin?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  expandedPanel: null,
  isExpanded:boolean;
  loading: boolean;
  inputValue:string,
  token: null | string;
  toggleDrawer: boolean;
  userToken: string,
  userDetails: {
    attributes: {
      first_name: string;
      role: string;
      image: { url: string },
    },
  },
  CardDetailsData: Array<{
    id: string | number, heading: string,
    points: string, imageUrl: string,
    PublishDate: string, AnnouncementType: string,
    ContryName: Array<string>
  }>,
  page: number,
  valueKey: string;
  updateModalOpen: boolean,
  updateValue: string,
  startDate: null | Date,
  endDate: null | Date,
  formattedStartDate: string,
  formattedEndDate: string,
  StundetData: {
    id: string,
    type: string;
    student_id: string;
    first_name: string;
    last_name: string;
    gender: string;
    country: string;
    country_code: string | null;
    marital_status: string;
    state: string;
    emergency_contact_email: string,
    city: string;
    mobile: string;
    email: string;
    emergency_contact_person: string;
    emergency_contact_mobile: string;
    current_status: string;
    passport_number: string;
    associate_branch_name: string;
    created_at: string;
    updated_at: string;
    agent_name: string;
    agent_id: string;
    documents: {
      data: Array<{
        Name: string, Id: string,
        FileType: string, DocumentCategory: string,
        AccountId: string, Comment: string,
        Status: string, FileUrl: string, FileSize: string
      }>
    };
    image: string | null;
    country_of_nationality: string | null,
    country_of_residence: string | null,
  },
  IsFormEdit: boolean,
  AssociateName: Array<{ id: number, name: string }>,
  EditFormData: {
    FirstName: string,
    LastName: string,
    Gender: string,
    MaritalStatus: string,
    Countryofnationality: string,
    Countryofresidence: string,
    State: string,
    City: string,
    PassportNumber: string,
    Mobile: string,
    Email: string,
    AssociatedBranch: string,
    EmergencyContactPerson: string,
    EmergencyMobile: string,
    EmergencyEmail: string,
    UserImage: string | null,
    AddVisaRefusal: Array<{ country: string, date: string }>,
    TravelHistory: Array<{ country: string, date: string }>,
  },
  FormikFormReinitialize: boolean,
  countryData: Array<CountryInterfaceData>;
  countryCodes: Array<{ alpha2: string; name: string; country_code: string; }>;
  contryCodeValue: string,
  emercontryCodeValue: string;
  stateData: Array<{ name: string }>;
  passportErrorMessage: string;
  isPassportErrorValid: boolean;
  IsStudentMobileValid: boolean;
  IsStudentMobileText: string;
  IsEmergencyMobileValid: boolean;
  IsEmergencyMobileText: string;
  ActiveTabIndex: number;
  ActiveTabIndex2: number;
  IsUploadDoumentModal: boolean;
  UploadDocumentAPIData: {
    APIKey: string,
    field_label: string,
    field_placeholder:string,
    id:string,
  },
  UploadFIle: File[],
  FileSize: string[],
  UploadErrorText: string[],
  RemarkText: string,
  IsProfilePictureUpdate: boolean,
  ProfilePictureFile: File[],
  ShortListApiResponseData: Array<{
    Id: string,
    Type: string,
    ProgramName: string,
    ProgramLink: string,
    ProgramLevel: string,
    IsShortlisted: boolean,
    Discipline: string,
    FeesAfterScholarship: string,
    Fees: string,
    Duration: string
    CashDeposit: string
    UniversityId: string,
    UniversityName: string,
    UniversityLocation: string,
    UniversityProvince: string,
    UniversityYear: string,
    UniversityCountry: string,
    UniversityLogo: string,
    UniversityLogoFileName: string,
    UniversityOfferTat: string,
    AppId:string,
    Sessions: Array<{
      IntakeLabel: string,
      Availability: string,
      Month: Array<string>
    }>
  }>,
  VisaAndTravelData: Array<{
    date: string,
    country: string,
  }>,
  IsVisaAndTravelModelOpen: boolean,
  VisaAndTravelTitle: string,
  AskForExpertModel: boolean,
  VisionStaffData: any,
  studentServices: StudentServiceData;
  CourseInfo:any,
  allDocuments:any,
  otherApps:any,
  eligibilty:any,
  applicationReq:any,
  ApplicationDataList:any,
  otherService: Array<OtherService>,
  isStudentModal: boolean,
  ModelServicesProvider: string,
  modalImg: string;
  modalTitle: string;
  otherServiceIdValue: string;
  isSuccess: boolean,
  successMessage:SuccessMessageResponse;
  studentError: string;
  totalConvData:any;
  convCount:Number;
  isVisible:Boolean;
  ReAssigneModelState: boolean,
  ReAssigneModelData: ReassignModelDataType,
  associateValue: string | unknown,
  reassignAgentError: string;
  AgentId:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}
// Customizable Area Start
interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: { url: string };
  };
}

interface ApplicationDataListType {
  data: StudentApplication;
}

interface StudentApplication {
  id: string;
  type: string;
  attributes: ApplicationAttributes;
}

interface ApplicationAttributes {
  discontinued_reason: any;
  past_history: any;
  agent_branch_name: any;
  updated_at: any;
  fees: any;
  university_name: any;
  student_name: any;
  current_stage: any;
  university_id: any;
  application_id: string;
  student_id: number;
  generated_student_id: string;
  agent_id: number;
  assignee_id: number;
  application_status: string;
  type:string,
  application_stage: {
    application_fees_paid: boolean;
    application_received: boolean;
    cas_loa_applied: boolean;
    conditional_offer: boolean;
    pre_cas_loa_stage: boolean;
    submitted_to_institution: boolean;
    tuition_fees_paid: boolean;
    unconditional_offer: boolean;
    visa_applied: boolean;
    visa_received: boolean;
    refund_initiated?: boolean,
    refund_received?: boolean,
    refund_rejected?: boolean,
    refund_submitted_to_institution?: boolean
    cas_loa_received: boolean
  };
  course: string;
  university: string;
  agent_email: string;
  applied_by: string;
  start_date: string;
}

interface Requirement {
    id: string;
    status: string;
    task_name: string;
    task_description: string;
    sample_file_url?: string;
  }

  const requirements: Requirement[] = [
  ];
interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
  error?: string[];
  success: string[]
}
interface Student {
  student_name: string;
  student_id: string;
  id: number;
  passport_number: string;
}
interface ProfileImage {
  id: number;
  filename: string;
  url: string;
  type: string;
}
interface Agent {
  id: number;
  agent_name: string;
  agent_id: string;
  email: string;
  designation: string;
  profile_image: ProfileImage;
}
interface ReassignModelDataType {
  message(message: any): unknown;
  students: Student[];
  agents: Agent[];
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export const configJSON = require("./config");

export interface StudentAPITypes {
  student: {
    data: {
      id: string;
      type: string;
      attributes: {
        student_id: string;
        first_name: string;
        last_name: string;
        gender: string;
        country: string;
        country_code: string | null;
        marital_status: string;
        country_of_nationality: string | null;
        country_of_residence: string | null;
        state: string;
        city: string;
        mobile: string;
        email: string;
        emergency_contact_person: string;
        emergency_contact_mobile: string;
        current_status: string;
        passport_number: string;
        associate_branch_name: string;
        created_at: string;
        updated_at: string;
        agent_name: string;
        emergency_contact_email: string,
        agent_id: string;
        emergency_country_code: string | null,
        travel_history: {
          data: Array<
            {
              id: string,
              type: string,
              attributes: {
                date: string,
                country: string
              }
            }
          >
        };
        visa_refusal: {
          data: Array<
            {
              id: string,
              type: string,
              attributes: {
                date: string,
                country: string
              }
            }
          >
        };
        documents: {
          data: Array<{
            id: string,
            type: string,
            attributes: {
              id: number,
              name: string,
              file_type: string,
              document_category: string,
              account_id: string,
              created_at: string,
              updated_at: string,
              comment: string,
              status: string,
              url: {
                url: string
              },
              file_size: string
            }
          }>
        };
        image: {
          filename: string,
          id: number,
          type: string,
          url: string
        } | null;
      }
    }
  }
}
interface UniversityLogo {
  id: number;
  filename: string;
  url: string;
  type: string;
}


interface University {
  id: number;
  university_name: string;
  location: string;
  province: string;
  country: string;
  associated_tags: string;
  offer_tat: string;
  year: number;
  logo: UniversityLogo;
}

interface Session {
  intake_label: string;
  months: string[];
  availability: string;
}

interface ProgramAttributes {
  program_name: string;
  program_level: string;
  duration: string;
  fees: string;
  fees_after_scholarship: string;
  cas_deposit: string;
  program_link: string;
  discipline: string;
  is_shortlisted: boolean;
  university: University;
  sessions: Session[];
}

interface ProgramData {
  id: string;
  type: string;
  attributes: ProgramAttributes;
}

interface ShortListApiResponse {
  data: ProgramData[];
}
interface SalesManagerInfo {
  data: {
    id: string;
    type: string;
    attributes: {
      first_name: string;
      last_name: string;
      phone_number: string;
      email: string;
      status: string;
      image: string;
    };
  };
}
interface AssignedStaffInfo {
  id: string;
  type: string;
  attributes: {
    university_staff_info: object;
    sales_manager_info: SalesManagerInfo;
  };
}

// Define the structure for the overall response
interface AssignedStaffResponseData {
  data: AssignedStaffInfo[]; // Array of assigned staff info
  meta: {
    message: string; // e.g., "Assigned Staff information"
  };
}

interface StudentServiceData {
  data: StudentService[];
}

interface StudentService {
  id: string;
  type: string;
  attributes: StudentServiceAttributes;
}

interface StudentServiceAttributes {
  request_id: string;
  status: "PENDING" | "APPROVED" | "REJECTED";
  service: string;
  logo: string;
  student_name: string;
  date: string;
  time: string;
}

// Customizable Area End

export default class ViewApplicationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileAPICallId: string = "";
  GetStudentsAPICallId: string = "";
  getAssociateBranchNamesAPICallID: string = "";
  getCountryCodeAPICallID: string = "";
  validatePassportAPICallID: string = "";
  validateStudentMobileNoAPICallID: string = "";
  validateEmergencyMobileNoAPICallID: string = "";
  StudentUpDateAPICall: string = "";
  DeleteDoumentApiCallId: string = "";
  UploadDocumentForStudentAPICallId: string = "";
  GetAssignStaffCallId: string = "";
  GetCourseInfoCallId: string = "";
  GetDocumentsId:string="";
  GetOtherAppsInfoCallId: string="";
  GetEligibiltyCallId: string ="";
  GetApplicationResCallId: string="";
  getAplicationDataListApiCallId: string = "";
  getAplicationDataListApiCallIdNew: string = "";
  otherServiceCallId: string = "";
  openAccountCallId: string = "";
  totalConvId: string="";
  GetReAssigneModelDataCallId: string = "";
  reassignAllAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      expandedPanel: null,
      isExpanded: false,
      inputValue:'',
      loading: true,
      token: null,
      toggleDrawer: false,
      userToken: '',
      userDetails: {
        attributes: {
          first_name: '',
          role: '',
          image: {
            url: '',
          },
        },
      },
      CardDetailsData: [],
      page: 1,
      valueKey: '',
      updateModalOpen: false,
      updateValue: '',
      startDate: null,
      endDate: null,
      formattedStartDate: "",
      formattedEndDate: "",
      StundetData: {
        id: '',
        type: '',
        student_id: "",
        first_name: "",
        last_name: "",
        gender: "",
        country: "",
        country_code: "",
        marital_status: "",
        state: "",
        city: "",
        mobile: "",
        email: "",
        emergency_contact_person: "",
        emergency_contact_mobile: "",
        current_status: "",
        passport_number: "",
        associate_branch_name: "",
        created_at: "",
        updated_at: "",
        agent_name: "",
        agent_id: "",
        documents: { data: [] },
        image: "",
        country_of_nationality: "",
        country_of_residence: "",
        emergency_contact_email: ""
      },
      IsFormEdit: false,
      AssociateName: [],
      countryCodes: [],
      contryCodeValue: "",
      emercontryCodeValue: "",
      EditFormData: {
        FirstName: "",
        LastName: "",
        Gender: "",
        MaritalStatus: "",
        Countryofnationality: "",
        Countryofresidence: '',
        State: "",
        City: "",
        PassportNumber: "",
        Mobile: "",
        Email: "",
        AssociatedBranch: "",
        EmergencyContactPerson: "",
        EmergencyMobile: "",
        EmergencyEmail: "",
        UserImage: "",
        AddVisaRefusal: [{
          country: "",
          date: ""
        }],
        TravelHistory: [{
          country: "",
          date: ""
        }]
      },
      FormikFormReinitialize: false,
      countryData: Country.getAllCountries() as never,
      stateData: [],
      passportErrorMessage: "",
      isPassportErrorValid: false,
      IsStudentMobileValid: false,
      IsStudentMobileText: "",
      IsEmergencyMobileValid: false,
      IsEmergencyMobileText: "",
      ActiveTabIndex: 0,
      ActiveTabIndex2: 0,
      IsUploadDoumentModal: false,
      UploadDocumentAPIData: {
        APIKey: "",
        field_label:"",
        field_placeholder:"",
        id:"",
      },
      UploadFIle: [],
      FileSize: [],
      UploadErrorText: [],
      RemarkText: "",
      IsProfilePictureUpdate: false,
      ProfilePictureFile: [],
      ShortListApiResponseData: [],
      VisaAndTravelData: [],
      IsVisaAndTravelModelOpen: false,
      VisaAndTravelTitle: "",
      AskForExpertModel: false,
      VisionStaffData: [],
      studentServices: {
        data: []
      },
      CourseInfo:[],
      allDocuments:[],
      otherApps:[],
      eligibilty:[],
      applicationReq:[],
      ApplicationDataList: {},
      otherService: [],
      isStudentModal: false,
      ModelServicesProvider: "",
      modalImg: "",
      modalTitle: "",
      otherServiceIdValue: "",
      isSuccess:false,
      successMessage: {} as SuccessMessageResponse,
      studentError:"",
      totalConvData:{},
      convCount:0,
      isVisible: window.innerWidth > 768,
      ReAssigneModelState: false,
      ReAssigneModelData: {} as ReassignModelDataType,
      associateValue: "none",
      reassignAgentError:'',
      AgentId: '',
    };

    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.GetAPICallFuncation(apiRequestCallId, responseJson)
        this.getAssosiateAPICall(apiRequestCallId, responseJson)
        if (apiRequestCallId === this.getCountryCodeAPICallID) {
          this.setCountryCodes(responseJson)
        }
        this.StudenAPiCallRecied(apiRequestCallId, responseJson)
        this.DeleteAPICallFuncationResponseSet(apiRequestCallId, responseJson)
        this.UploadDocumentAPICallResponse(apiRequestCallId, responseJson)
        this.GetAssignedStaffAPICallResponse(apiRequestCallId, responseJson)
        this.GetCourseInfoAPICallResponse(apiRequestCallId,responseJson)
        this.GetDocumentsInfoAPICallResponse(apiRequestCallId,responseJson);
        this.GetOtherAppsAPICallResponse(apiRequestCallId,responseJson);
        this.GetEligibiltyResponse(apiRequestCallId, responseJson);
        this.GetapplicationReqCallResponse(apiRequestCallId,responseJson)
        this.totalConvCallBackResponse(apiRequestCallId,responseJson);
        this.GetAplicationDataListApiResponse(apiRequestCallId, responseJson)
        this.SetReassignModelData(apiRequestCallId, responseJson)
        this.reAssignmessageData(apiRequestCallId, responseJson)

        const callbacks = {
          [this.openAccountCallId]: (responseJson: any) => {
            responseJson.error ? this.openAccountFailureCallBack(responseJson) : this.openAccountSucessCallBack(responseJson);
          },
          [this.otherServiceCallId]: (responseJson: any) => {
            this.otherServiceSucessCallBack(responseJson);
          }
        };
        
        const callback = callbacks[apiRequestCallId];
        if (callback) {
          callback(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  togglePanel = (panelId: any) => {
    this.setState((prevState) => ({
      expandedPanel: prevState.expandedPanel === panelId ? null : panelId,
    }));
  };

  UploadDocumentAPICallResponse = async (apiRequestCallId: string, responseJson: { message: string, error?: Array<string> }) => {
    if (apiRequestCallId === this.UploadDocumentForStudentAPICallId) {
      if (responseJson.message === "Files uploaded and requirement updated successfully") {
        const id = await getStorageData("StudentId")
        this.getApplicationResApi();
        this.CloseUploadModel()
        toast.success(responseJson.message)
      } else {
        if (responseJson.error) {
          this.CloseUploadModel()
          this.setState({
            loading: false
          })
          toast.error(responseJson.error)
          this.ErrorToastHander({ error: [{ message: responseJson.error[0] }] })
        }
      }
    }
  }

private mapApplicationStageData(applicationStage: any) {
  return {
    application_fees_paid: applicationStage?.application_fees_paid || false,
    application_received: applicationStage?.application_received || false,
    cas_loa_applied: applicationStage?.cas_loa_applied || false,
    cas_loa_received: applicationStage?.cas_loa_received || false,
    conditional_offer: applicationStage?.conditional_offer || false,
    pre_cas_loa_stage: applicationStage?.pre_cas_loa_stage || false,
    submitted_to_institution: applicationStage?.submitted_to_institution || false,
    tuition_fees_paid: applicationStage?.tuition_fees_paid || false,
    unconditional_offer: applicationStage?.unconditional_offer || false,
    visa_applied: applicationStage?.visa_applied || false,
    visa_received: applicationStage?.visa_received || false,
  };
}

private mapEnrolledStageData(applicationStage: any){
  return {
    commision_recieved_from_institution : applicationStage?.commision_recieved_from_institution || false,
    commision_credited_to_wallet:applicationStage?.commision_credited_to_wallet || false,
    // commision_denied_from_university:applicationStage?.commision_denied_from_university || false
  };
}

 mapEnrolledStageDataFInal(applicationStage: any){
  return {
    // commision_recieved_from_institution : applicationStage?.commision_recieved_from_institution || false,
    // commision_credited_to_wallet:applicationStage?.commision_credited_to_wallet || false,
    commision_denied_from_university:applicationStage?.commision_denied_from_university || false
  };
}

private mapRefundStageData(applicationStage: any) {
  return {
    refund_initiated: applicationStage?.refund_initiated || false,
    refund_received: applicationStage?.refund_received || false,
    refund_rejected: applicationStage?.refund_rejected || false,
    refund_submitted_to_institution: applicationStage?.refund_submitted_to_institution || false,
  };
}

GetAplicationDataListApiResponse = (apiRequestCallId: string, responseJson: ApplicationDataListType) => {
  if (apiRequestCallId === this.getAplicationDataListApiCallId) {
    const dataObject = responseJson.data;
    const attributes = dataObject.attributes;

    const ApiResponse = {
      UniversityId: attributes.university_id,
      AppId: attributes.application_id,
      Type: dataObject.type,
      ApplicationId: attributes.application_id,
      StudentId: `${attributes.student_id || ''}`,
      GeneratedStudentId: attributes.generated_student_id,
      AgentId: `${attributes.agent_id || ''}`, 
      AssigneeId: `${attributes.assignee_id || ''}`, 
      ApplicationStatus: attributes.application_status,
      CurrentStage: attributes.current_stage,
      StudentName: attributes.student_name,
      UniversityName: attributes.university_name,
      Fees: attributes.fees,
      ApplicationStageForInprogress: this.mapApplicationStageData(attributes.application_stage),
      ApplicationStageForEnrolled: this.mapEnrolledStageData(attributes.application_stage),
      ApplicationStageForEnrolledFinal: this.mapEnrolledStageDataFInal(attributes.application_stage),
      ApplicationStageForRefund: this.mapRefundStageData(attributes.application_stage),
      ApplicationStageForDiscontinue:attributes.discontinued_reason,
      course: attributes.course,
      University: attributes.university,
      AgentEmail: attributes.agent_email,
      AppliedBy: attributes.applied_by,
      StartDate: attributes.start_date,
      UpdatedAt: attributes.updated_at,
      AgentBranchName: attributes.agent_branch_name,
      pastHistory: attributes.past_history,
    };

    this.setState({
      ApplicationDataList: ApiResponse,
      loading: false,
    });
    this.geteligibilty()
  }
};
  SuccessStperCount = (data: {
    application_fees_paid: boolean;
    application_received: boolean;
    cas_loa_applied: boolean;
    conditional_offer: boolean;
    pre_cas_loa_stage: boolean;
    submitted_to_institution: boolean;
    tuition_fees_paid: boolean;
    unconditional_offer: boolean;
    visa_applied: boolean;
    visa_received: boolean;
    cas_loa_received: boolean
  }) => {
    let SuccessCount = -1; 
    const {
      application_fees_paid,
      application_received,
      cas_loa_applied,
      conditional_offer,
      pre_cas_loa_stage,
      submitted_to_institution,
      tuition_fees_paid,
      unconditional_offer,
      visa_applied,
      visa_received,
      cas_loa_received
    } = data;

    if (application_fees_paid) {
      SuccessCount += 1;
    }
    if (application_received) {
      SuccessCount += 1;
    }
    if (cas_loa_applied) {
      SuccessCount += 1;
    }
    if (cas_loa_received) {
      SuccessCount += 1;
    }
    if (conditional_offer) {
      SuccessCount += 1;
    }
    if (pre_cas_loa_stage) {
      SuccessCount += 1;
    }
    if (submitted_to_institution) {
      SuccessCount += 1;
    }
    if (tuition_fees_paid) {
      SuccessCount += 1;
    }
    if (unconditional_offer) {
      SuccessCount += 1;
    }
    if (visa_applied) {
      SuccessCount += 1;
    }
    if (visa_received) {
      SuccessCount += 1;
    }

    return SuccessCount;

  }

  SuccessStperCountEnrolled = (data: {
    commision_recieved_from_institution?: boolean;
    commision_credited_to_wallet?: boolean;
  }) => {
    let SuccessCount = -1; // Start from -1 to align with the initial logic
  
  
    // Increment SuccessCount for each condition
    if (data?.commision_recieved_from_institution) {
      SuccessCount += 1;
    }
    if (data?.commision_credited_to_wallet) {
      SuccessCount += 1;
    }
  
    return SuccessCount;
  };

  SuccessStperCountEnrolledFinal = (data: {
    commision_denied_from_university?: boolean;
  }) => {
    let SuccessCount = 1; // Always starts from 1, as per the requirement.
  
    // Log the response data for debugging
  
    // Additional conditions can be added here if needed in the future:
    // const { commision_denied_from_university } = data;
    // if (commision_denied_from_university) {
    //   SuccessCount += 1;
    // }
  
    return SuccessCount;
  };
  
  

  RefundStperCount = (data: {
    refund_initiated?: boolean,
    refund_received?: boolean,
    refund_rejected?: boolean,
    refund_submitted_to_institution?: boolean
  }) => {
    let RefundCount = -1;
    const { refund_initiated, refund_received, refund_rejected, refund_submitted_to_institution } = data
    if (refund_initiated) {
      RefundCount += 1
    }
    if (refund_submitted_to_institution) {
      RefundCount += 1
    }
    if (refund_received) {
      RefundCount += 1
    }
    if (refund_rejected) {
      RefundCount += 1
    }
    return RefundCount
  }

  UploadFileMultipleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxFileSize: number = 10 * 1024 * 1024; // 10 MB in bytes
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const validFiles: File[] = [...this.state.UploadFIle];

      Array.from(selectedFiles).forEach((file) => {
        let Error = []
        if (file.size > maxFileSize) {
          Error.push(`${file.name} is too large. Maximum size is 10 MB.`)
          this.setState({
            UploadErrorText: Error
          })
        } else if (!this.isAcceptedFormat(file.name.split('.').pop()?.toLowerCase())) {
          Error.push(`${file.name} is not an accepted file type.`)
          this.setState({
            UploadErrorText: Error
          })
        } else {
          validFiles.push(file);
          const fileSizeInBytes = file.size;
          const fileSizeInKB = fileSizeInBytes / 1024;
          const fileSizeInMB = fileSizeInKB / 1024;

          let FilesSize = []
          if (fileSizeInMB > 1) {
            FilesSize.push(fileSizeInMB.toFixed(2) + "" + "Mb")
            this.setState({
              FileSize: FilesSize
            })
          } else {
            FilesSize.push(fileSizeInKB.toFixed(2) + "" + "KB")
            this.setState({
              FileSize: FilesSize
            })
          }
          this.setState({
            UploadFIle: validFiles,
            UploadErrorText: []
          })
        }
      });
    }

  }

  GetAplicationData = async () => {
    const viewAppData = JSON.parse(await getStorageData("ViewAppData"));
    const application_id = viewAppData?.id; 
        this.getAplicationDataListApiCallId = await this.ViewProfileApiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_leadmanagement/forms/view_application?application_id=${application_id}`,
        });
};
  DeleteAPICallFuncationResponseSet = async (apiRequestCallId: string, responseJson: { message: string }) => {
    if (apiRequestCallId === this.DeleteDoumentApiCallId) {
      if (responseJson.message === "Attached files deleted successfully") {
        toast.success(responseJson.message)
        const id = await getStorageData("StudentId")
        this.getApplicationResApi();
      }
    }
  }

  GetAPICallFuncation = (apiRequestCallId: string, responseJson: UserDetailsResponse) => {
    if (apiRequestCallId === this.getProfileAPICallId) {
      this.getProfileFormSuccessCallBack(responseJson)
    }
  }

  getAssosiateAPICall = (apiRequestCallId: string, responseJson: {
    data:
    Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        type: string;
        branch_name: string;
        email: string;
        country_code: string;
        phone_number: number;
        managed_by: string;
        date: string;
        time: string;
        created_at: Date;
        updated_at: Date;
      };
    }>
  }) => {
    if (apiRequestCallId === this.getAssociateBranchNamesAPICallID) {
      this.setAssociateNameResponse(responseJson)
    }
  }
  ErrorToastHander = (responseJson: { [key: string]: Array<{ [key: string]: string }> }) => {
    if (Array.isArray(responseJson.error)) {
      toast.error(responseJson.error[0])
    } else {
      toast.error(Object.values(responseJson.error)[0] as string)
    }
  }

  StudenAPiCallRecied = (apiRequestCallId: string, responseJson: StudentAPITypes) => {
    if (apiRequestCallId === this.GetStudentsAPICallId) {
      this.SetStudentDataAPIcall(responseJson)
    }
  }

  IsItValidValues = (value: string | null | undefined) => {
    if (typeof value === "string") {
      return value
    } else {
      return ""
    }

  }

  SetStudentDataAPIcall = (responseJson: StudentAPITypes) => {
    const AttributesResponse = responseJson.student.data.attributes;

    if (AttributesResponse) {
      const DoumentData = AttributesResponse.documents.data?.map((item) => {
        return {
          Name: item.attributes.name,
          Id: `${item.attributes.id}`,
          FileType: item.attributes.file_type,
          DocumentCategory: item.attributes.document_category,
          AccountId: item.attributes.account_id ?? '',
          Comment: item.attributes.comment ?? '',
          Status: item.attributes.status,
          FileUrl: item.attributes?.url?.url,
          FileSize: item.attributes.file_size
        }
      })

      this.setState({
        StundetData: {
          id: responseJson.student.data.id,
          type: responseJson.student.data.type,
          student_id: AttributesResponse.student_id,
          first_name: AttributesResponse.first_name,
          last_name: AttributesResponse.last_name,
          gender: AttributesResponse.gender,
          country: AttributesResponse.country,
          country_code: AttributesResponse.country_code,
          marital_status: AttributesResponse.marital_status,
          state: AttributesResponse.state,
          city: AttributesResponse.city,
          mobile: AttributesResponse.mobile,
          email: AttributesResponse.email,
          emergency_contact_person: AttributesResponse.emergency_contact_person,
          emergency_contact_mobile: AttributesResponse.emergency_contact_mobile,
          emergency_contact_email: AttributesResponse.emergency_contact_email,
          current_status: AttributesResponse.current_status,
          passport_number: AttributesResponse.passport_number,
          associate_branch_name: AttributesResponse.associate_branch_name,
          created_at: AttributesResponse.created_at,
          updated_at: AttributesResponse.updated_at,
          agent_name: AttributesResponse.agent_name,
          agent_id: AttributesResponse.agent_id,
          documents: { data: DoumentData },
          image: this.IsItValidValues(AttributesResponse.image?.url),
          country_of_nationality: AttributesResponse.country_of_nationality,
          country_of_residence: AttributesResponse.country_of_residence,
        },
        contryCodeValue: this.IsItValidValues(AttributesResponse.country_code),
        emercontryCodeValue: this.IsItValidValues(AttributesResponse.emergency_country_code),
      }, () => {
        const { StundetData } = this.state
        // this.LoderFalseTrue()
        this.getSalesManagerInfoApi();
        this.getCourseInfoApi();
        this.getOtherAppsInfoApi();
        const statedata = State.getAllStates()
        const filteredData = statedata.filter((data: { countryCode: string; }) => {
          return data.countryCode === StundetData.country_of_residence
        });
        this.setState({
          stateData: filteredData
        })
      });
    }

  }

  // LoderFalseTrue = () => {
  //   this.setState({
  //     loading: !this.state.loading
  //   })
  // }
  async componentDidMount() {
    const token = await getStorageData("token")
    const id = await getStorageData("StudentId")
    const viewAppData = JSON.parse(await getStorageData("ViewAppData"));
    this.GetStudentsData(id)
    this.geteligibilty()
    this.getSalesManagerInfoApi()
    this.getAllDocuments()
    this.getCourseInfoApi()
    this.getOtherAppsInfoApi();
    this.getApplicationResApi();
    this.setState({ userToken: token }, () => {
      this.getFormApprovalProfile()
      this.getAssociateBranchNames()
      this.getCountryCode()
    })
    this.GetAplicationData();
    this.getOtherServiceApi();
    this.getTotalConversation();
  }

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage",
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  ViewProfileApiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, body, type, endPoint } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageForViewMore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageForViewMore.id, requestMessageForViewMore);

    return requestMessageForViewMore.messageId;
  };


  getProfileFormSuccessCallBack = (responseJson: UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  getFormApprovalProfile = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  }

  GetStudentsData = async (id: string) => {
    this.GetStudentsAPICallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_dashboard/students/${id}`
    });
  }

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigationToAnyPageProfile = (pageName: string) => {
    const navigation0 = new Message(getName(MessageEnum.NavigationMessage));
    navigation0.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation0.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation0);
  };
  navigationToAnyPageProfileNew = (pageName: string) => {
    const navigation1 = new Message(getName(MessageEnum.NavigationMessage));
    navigation1.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation1.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation1);
  };

  navigationToAnyPageProfileFync= (pageName: string) => {
    const navigation2 = new Message(getName(MessageEnum.NavigationMessage));
    navigation2.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation2.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation2);
  };

  handleOpenClose = (value?: string) => {
    this.setState({
      updateModalOpen: !this.state.updateModalOpen,
      updateValue: `${value}`,
    });
  };
  getAssociateBranchNames = async () => {
    this.getAssociateBranchNamesAPICallID = await this.ViewProfileApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAssociateBranchNames
    });
  }
  setAssociateNameResponse = (response: {
    data:
    Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        type: string;
        branch_name: string;
        email: string;
        country_code: string;
        phone_number: number;
        managed_by: string;
        date: string;
        time: string;
        created_at: Date;
        updated_at: Date;
      };
    }>
  }) => {
    const data = response?.data.map((item) => {
      return {
        id: item.attributes.id,
        name: item.attributes.branch_name
      }
    })
    this.setState({
      AssociateName: data
    })
  }

  getCountryCode = async () => {
    this.getCountryCodeAPICallID = await this.ViewProfileApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCountryCode
    });
  }

  setCountryCodes = (responseJson: Array<{
    alpha2: string,
    name: string,
    country_code: string
  }>) => {
    this.setState({ countryCodes: responseJson })
    const defaultCountry = this.state.countryCodes.find((country) => country.alpha2 === 'IN');
    this.setState({ contryCodeValue: `${defaultCountry?.country_code}-${defaultCountry?.alpha2}`, emercontryCodeValue: `${defaultCountry?.country_code}-${defaultCountry?.alpha2}` })
  };

  DeleteDoument = async (DoumentDeleteId: string) => {
    const id = await getStorageData("StudentId")
    this.DeleteDoumentApiCallId = await this.ViewProfileApiCall({
      method: "DELETE",
      endPoint: `bx_block_leadmanagement/forms/delete_file?requirement_id=${DoumentDeleteId}`,
    });
  }

  HandelTabChange = (_e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      ActiveTabIndex: newValue
    })
  }

  HandelTabChange2 = (_e: React.ChangeEvent<{}>, newValue2: number) => {
    this.setState({
      ActiveTabIndex2: newValue2
    })
  }

  FilterDocumentData = (type: string) => {
    const { StundetData } = this.state;
    return StundetData.documents.data.filter((filter) => filter.DocumentCategory === type)
  }
  OpenDocument = (Path: string) => {
    window.open(Path, "_blank")
  }

  UploadDoumentModelOpen = (UploadDocKey: string,field_label:string,field_placeholder:string,id:string) => {
    this.setState({
      UploadDocumentAPIData: {
        APIKey: UploadDocKey,
        field_label:field_label,
        field_placeholder:field_placeholder,
        id:id,
      },
      IsUploadDoumentModal: true
    })
  }

  isAcceptedFormat = (fileExtension?: string): boolean => {
    const acceptedFormats = ['png', 'jpg', 'pdf', 'doc', 'docx'];
    return fileExtension ? acceptedFormats.includes(fileExtension) : false;
  };


  OnDeleteUpload = (index: number) => {
    this.setState({
      UploadErrorText: this.state.UploadErrorText.filter((_file, i) => i !== index)
    })
    this.setState({
      UploadFIle: this.state.UploadFIle.filter((_file, i) => i !== index)
    })
  }

  handleInputChange = (event: { target: { value: any; }; }) => {
    this.setState({ inputValue: event.target.value }); // Update state with input value
  };

  OnSaveUploadDocument = async () => {
    const { UploadFIle, UploadDocumentAPIData } = this.state;
    const formData = new FormData();
  
    UploadFIle.forEach((item) => {
      formData.append(`requirement_id`, UploadDocumentAPIData.id);
      formData.append(`file`, item);
      if(this.state.inputValue.length>0){
        formData.append(`value`,this.state.inputValue);
      }
    });
  
    if (UploadDocumentAPIData.APIKey === "Other Documents" && this.state.RemarkText !== "") {
      formData.append(`remarks`, this.state.RemarkText);
    }
    this.UploadDocumentForStudentAPICallId = await this.ViewProfileApiCall({
      method: "POST",
      endPoint: `/bx_block_leadmanagement/forms/application_requirement`,
      body: formData,
      type: "formData",
    });
  };
  

  CloseUploadModel = () => {
    this.setState({
      IsUploadDoumentModal: false,
      UploadFIle: [],
      UploadDocumentAPIData: {
        APIKey: "",
        field_label:'',
        field_placeholder:"",
        id:"",
      },
      UploadErrorText: [],
      RemarkText: ""
    })
  }

  RemarkOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      RemarkText: e.target.value
    })
  }

  // Assigned Staff work Start

  getSalesManagerInfoApi = async () => {
    const viewAppData = JSON.parse(await getStorageData("ViewAppData"));
    const application_id = viewAppData?.id;
    const { StundetData } = this.state
    this.GetAssignStaffCallId = await this.ViewProfileApiCall({
        method: configJSON.httpGetMethod,
        contentType: configJSON.validationApiContentType,
        endPoint: `/bx_block_dashboard/students/assigned_staff?student[application_id]=${application_id}&student[email]=${StundetData?.email}`
    });
  };

  getAllDocuments = async () => {
    const viewAppData = JSON.parse(await getStorageData("ViewAppData"));
    const application_id = viewAppData?.id; // Safely access the 'id'

    if (application_id) {
        this.GetDocumentsId = await this.ViewProfileApiCall({
            method: configJSON.httpGetMethod,
            contentType: configJSON.validationApiContentType,
            endPoint: `${configJSON.documentsFetch}?application_id=${application_id}`,
        });
    } 
};

  getCourseInfoApi = async () => {
    const viewAppData = JSON.parse(await getStorageData("ViewAppData"));
    const application_id = viewAppData?.id;
    const id = await getStorageData("StudentId")
    const { StundetData } = this.state
    this.GetCourseInfoCallId = await this.ViewProfileApiCall({
        method: configJSON.httpGetMethod,
        contentType: configJSON.validationApiContentType,
        endPoint: `${configJSON.courseInfoEndPoint}?student_id=${StundetData?.student_id}&id=${id}&application_id=${application_id}`
    });
  };

  getOtherAppsInfoApi = async () => {
    const id = await getStorageData("StudentId")
    const { StundetData } = this.state
    this.GetOtherAppsInfoCallId = await this.ViewProfileApiCall({
        method: configJSON.httpGetMethod,
        contentType: configJSON.validationApiContentType,
        endPoint: `${configJSON.otherAppsEndPoint}?student_id=${StundetData?.student_id}&id=${id}`
    });
  };

  geteligibilty= async () => {
    const id = this.state.ApplicationDataList?.UniversityId;
    this.GetEligibiltyCallId = await this.ViewProfileApiCall({
        method: configJSON.httpGetMethod,
        contentType: configJSON.validationApiContentType,
        endPoint: `${configJSON.eligibility}?university_id=${id}`
    });
  };

  getApplicationResApi = async () => {
    const viewAppData = JSON.parse(await getStorageData("ViewAppData"));
    const application_id = viewAppData?.id;

    if (application_id) {
        this.GetApplicationResCallId = await this.ViewProfileApiCall({
            method: configJSON.httpGetMethod,
            contentType: configJSON.validationApiContentType,
            endPoint: `${configJSON.AppResEndPoint}?application_id=${application_id}`,
        });
    }
};


  GetAssignedStaffAPICallResponse = (apiRequestCallId: string, responseJson: AssignedStaffResponseData) => {
    if (apiRequestCallId === this.GetAssignStaffCallId) {
    this.setState({VisionStaffData:responseJson?.data})
    }
  }

  GetCourseInfoAPICallResponse = (apiRequestCallId: string, responseJson: AssignedStaffResponseData) => {
    if (apiRequestCallId === this.GetCourseInfoCallId) {
    this.setState({CourseInfo:responseJson})
    }
  }

  GetDocumentsInfoAPICallResponse = (apiRequestCallId: string, responseJson: AssignedStaffResponseData) => {
    if (apiRequestCallId === this.GetDocumentsId) {
    this.setState({allDocuments:responseJson})
    }
  }

  GetOtherAppsAPICallResponse = (apiRequestCallId: string, responseJson: AssignedStaffResponseData) => {
    if (apiRequestCallId === this.GetOtherAppsInfoCallId) {
    this.setState({otherApps:responseJson})
    }
  }

  GetEligibiltyResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.GetEligibiltyCallId) {
    this.setState({eligibilty:responseJson.data})
    }
  }

  GetapplicationReqCallResponse = (apiRequestCallId: string, responseJson: AssignedStaffResponseData) => {
    if (apiRequestCallId === this.GetApplicationResCallId) {
    this.setState({applicationReq:responseJson})
    }
  }

  totalConvCallBackResponse = (apiRequestCallId:string,responseJson:any)=>{
    if(apiRequestCallId=== this.totalConvId){
      this.setState({ totalConvData: responseJson});
    }
  };

  StperData = () => {
    let data = ["Application Fees Paid", "Application Received", "Submitted to Institution", "Conditional Offer", "Unconditional Offer", "Tuition Fees Paid", "Pre-CAS/ LOA Stage", "CAS/LOA Applied", "CAS/LOA Received", "Visa Applied", "Visa Received"]
    return data
  }

  SecondStperData = () => {
    let data = [
      "Refund initiated",
      "Refund submitted to institution",
      "Refund Received",
      "Refund Rejected"
    ]
    return data
  }

  ThirdStperData = () => {
    let data = [
      "Received from Institution",
      "Credited to wallet",
      "Denied from University",
    ]
    return data
  }

  NaviGateToViewApplication = (id: string, AppId: string) => {
    setStorageData("ViewAppData", JSON.stringify({ id, AppId }))
    window.location.reload();
  }
  getOtherServiceApi = async () => {
    this.otherServiceCallId = await this.ViewProfileApiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.getOtherServiceEndPoint
    });
  };

  getTotalConversation= async ()=>{
    this.totalConvId = await this.ViewProfileApiCall({
      method: configJSON.httpGetMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.totalConv,
  });
  }

  otherServiceSucessCallBack = (responseJson:OtherServiceData)=>{
    this.setState({ otherService: responseJson.data});
  };

  postOpenAccountApi = async () => {
    const formdata = new FormData();
    formdata.append("student_id", this.state.StundetData.student_id);
    formdata.append("other_service_id", this.state.otherServiceIdValue);
    this.openAccountCallId = await this.ViewProfileApiCall({
      method: configJSON.postApiMethod,
      endPoint: configJSON.openAccountEndpoint,
      body: formdata,
      type: configJSON.formData
    });
  };

  openCloseStudentModal = (title: string,imgTitle: string | null | undefined,idvalue: string,service:string) => {
    this.setState((prevState) => ({
      isStudentModal: !prevState.isStudentModal,
      modalTitle: title,
      modalImg: imgTitle || prevState.modalImg,
      otherServiceIdValue: idvalue || prevState.otherServiceIdValue,
      ModelServicesProvider: service,
      studentError: "",
    }));
  };

  openAccountSucessCallBack = (responseJson: SuccessMessageResponse) => {
    this.setState({
      isStudentModal: !this.state.isStudentModal,
      isSuccess: !this.state.isSuccess,
      successMessage: responseJson
    })
  };

  openCloseSuccssModal = () => {
    this.setState({
      isSuccess: !this.state.isSuccess
    });
  };

  requestIdTxt = () => {
    return this.state.successMessage?.request_id?.split("Your request id is ")[1]
  };

  openAccountFailureCallBack = (responseJson: any) => {
    this.setState({
      studentError: responseJson.error
    })
  };
  UpadtedCount = (num:Number) =>{
    this.setState({convCount:num})
    this.getTotalConversation()
  }
  toggleVisibility = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };


  OpenReAssigneModel = (id: string) => {
    this.GetReAssigneModelDataApiCallFuncation(id)
    this.setState({
      AgentId: id
    })
  }

  GetReAssigneModelDataApiCallFuncation = async (id: string) => {
    this.GetReAssigneModelDataCallId = await this.ViewProfileApiCall({
      method: configJSON.httpGetMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: "bx_block_roles_permissions/roles_and_permissions/set_reassign_student?id=" + id
    })
  }

  SetReassignModelData = (apiRequestCallId: string, responseJson: ReassignModelDataType) => {
    if (apiRequestCallId === this.GetReAssigneModelDataCallId) {
      this.setState({
        ReAssigneModelData: responseJson,
        ReAssigneModelState: true,
      })
    }
  }

  reAssignmessageData = (apiRequestCallId: string, responseJson: ReassignModelDataType) => {
    if (apiRequestCallId === this.reassignAllAPICallId) {
      toast.success(responseJson.message)
      this.OnCloseReAssigneModelState()
        }
  }

  OnCloseReAssigneModelState = () => {
    this.setState({
      ReAssigneModelState: false
    })
  }

  handleBranchChange = (event: any) => {
    this.setState({ associateValue: event.target.value, reassignAgentError: "" });
  };

  reassignStudentApiCallFuncation = async () => {
    const { associateValue } = this.state;

    const isValidAssociateValue = associateValue !== "none";


    if (!isValidAssociateValue) {
      this.setState({ reassignAgentError: "Please select agent/counsellor" });
      return;
    }

    const { StundetData } = this.state
    const idArray: string[] = [StundetData.id];
    const studentIdArray: string[] = [StundetData.student_id];

    const body = {
      student_id: studentIdArray,
      id: idArray,
      agent_id: associateValue
    }

    this.reassignAllAPICallId = await this.ViewProfileApiCall({
        endPoint: "bx_block_roles_permissions/roles_and_permissions/reassign_student",
        contentType: "application/json",
        body: body,
        method: "PUT"
    });
  }
  // Customizable Area End
}