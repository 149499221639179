import React from "react";
// Customizable Area Start
import LeadManagementoController, {
  Props, StudentAttributes
} from "./LeadManagementController";
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import { accountOutline } from './assets';
import { styled, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TableSortLabel, Modal, Select } from '@material-ui/core';
import { config } from "process";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import Pagination from "@material-ui/lab/Pagination";
export const configJSON = require("./config");
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { KeyboardArrowDown, Close } from "@material-ui/icons";

//added packages for Autocomplete
import { TextField, Checkbox, ListItemText, MenuItem, Chip, SvgIcon } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

// added for cancel icon icon
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// added for calender
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Loader from "../../../components/src/Loader.web";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

interface Student {
  student_name: string;
  student_id: string;
}

interface Agent {
  agent_name: string;
  agent_id: string;
}
// Customizable Area End

export default class LandingPage extends LeadManagementoController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

  renderFilter = () => {
    const { startDate, endDate, options } = this.state;
    const { students, associate_branch_name, created_by } = options;
    const maxDate = moment().endOf('day');
    const disableEndDatePicker = !startDate;
    const dateFormat = "DD-MMM-YYYY";
    return (
      <Modal open={this.state.filterOpen}
        onClose={this.onFilterClick} data-test-id="filterModal">
        <ModalContent>
          <BoxPopupClose>
            <Close data-test-id="modalTestID" onClick={this.onFilterClick} />
          </BoxPopupClose>
          <ModaInnerBox >
            <Box>
              <BoxPopUpTitle className="filterTitle"><FilterListOutlinedIcon/><FilterModalTitle>Filter</FilterModalTitle></BoxPopUpTitle>
            </Box>
            <Box className="insideBox">
              <Box className="innerContent">
                <Typography className="inputTxt">
                  {configJSON.student}
                </Typography>
                <Autocomplete
                  data-test-id="selectStudent"
                  className="selectInput autocompleteField"
                  multiple
                  options={students}
                  getOptionLabel={(option:Student) => option.student_name}
                  value={this.state.studentFilterSelected}
                  disableCloseOnSelect
                  onChange={this.handleSelectionChange}
                  renderOption={(props, option: Student, { selected }) => {
                    const { key, ...otherProps } = props;
                    return (
                      <Box component="li" key={option.student_id} {...otherProps}>
                        <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                        <ListItemText
                          primary={option.student_name}
                          secondary={
                            <>
                              <strong>Student ID:</strong> {option.student_id}
                            </>
                          }
                        />
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={configJSON.autoCompleteStudent}
                      InputLabelProps={{ shrink: true,  ...params.InputProps }}
                    />
                  )}
                  renderTags={() => null}
                />
                <Box mt={2}>
                  {this.state.studentFilterSelected.map((option, index) => (
                    <StyledChip
                      data-test-id="selectedStudent"
                      key={option.student_id}
                      label={option.student_name}
                      onDelete={() => this.handleRemoveItem(option.student_id)}
                      deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                    />
                  ))}
                </Box>
              </Box>

              <Box className="innerContent">
                <Typography className="inputTxt">
                  {configJSON.associateBranch}
                </Typography>
                <Select
                  className="selectInput"
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                  }}
                  style={{
                    color: this.state.associateValue == "none" ? "#999" : "#000"
                  }}
                  value={this.state.associateValue}
                  data-test-id="selectBranch"
                  IconComponent={KeyboardArrowDown}
                  onChange={this.handleBranchChange}
                >
                  <MenuItem value="none">
                    {configJSON.selectBranchPlaceholder}
                  </MenuItem>
                  {associate_branch_name.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box className="innerContent">
                <Typography>
                  {configJSON.createdBy}
                </Typography>
                <Autocomplete
                  data-test-id="selectCreatedBy"
                  className="selectInput autocompleteField"
                  multiple
                  options={created_by}
                  getOptionLabel={(option:Agent) => option.agent_name}
                  value={this.state.agentFilterSelected}
                  disableCloseOnSelect
                  onChange={this.agentChange}
                  renderOption={(props, option: Agent, { selected }) => {
                    const { key, ...otherProps } = props;
                    return (
                      <Box component="li" key={option.agent_id} {...otherProps}>
                        <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                        <ListItemText
                          primary={option.agent_name}
                          secondary={
                            <>
                              <strong>Agent ID:</strong> {option.agent_id}
                            </>
                          }
                        />
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={configJSON.selectCreatedByPlaceholder}
                      InputLabelProps={{ shrink: true,  ...params.InputProps }}
                    />
                  )}
                  renderTags={() => null}
                />
                <Box mt={2}>
                  {this.state.agentFilterSelected.map((option, index) => (
                    <StyledChip
                      data-test-id="selectedAgent"
                      key={option.agent_id}
                      label={option.agent_name}
                      onDelete={() => this.handleRemoveAgent(option.agent_id)}
                      deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                    />
                  ))}
                </Box>
              </Box>

              <Box className="innerContent">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Box style={{ display: 'flex', gap: '1rem'}} className="innerContent-date">
                    <DatePicker
                      data-test-id="selectFromDate"
                      placeholder="Start Date"
                      value={startDate}
                      onChange={this.handleStartDateChange}
                      inputVariant="outlined"
                      TextFieldComponent={(props:any) => <TextField {...props} />}
                      maxDate={maxDate}
                      disableFuture
                      format={dateFormat}
                    />
                    <DatePicker
                      data-test-id="selectToDate"
                      placeholder="End Date"
                      value={endDate}
                      onChange={this.handleEndDateChange}
                      inputVariant="outlined"
                      TextFieldComponent={(props:any) => <TextField {...props} />}
                      maxDate={maxDate}
                      disableFuture
                      disabled={disableEndDatePicker}
                      minDate={startDate}
                      format={dateFormat}
                    />
                  </Box>
                </MuiPickersUtilsProvider>
                {startDate && endDate && (
                  <SelectedDateWrapper>
                    <DateWrapper>
                      <DateTypoGraphy data-test-id="selectedFromDate">
                        {startDate?.format(dateFormat)}
                      </DateTypoGraphy>
                      <DateTypoGraphy data-test-id="selectedToDate">
                        to {endDate?.format(dateFormat)}
                      </DateTypoGraphy>
                    </DateWrapper>
                    <SvgIcon onClick={this.handleDateReset} component={CancelOutlinedIcon} style={{ color: 'red' }} />
                  </SelectedDateWrapper>
                )}
              </Box>
            </Box>
            <StyledBoxButtonWrapper>
              <StyledButtonReset data-test-id="resetFilter" onClick={this.handleReset}><StyledTypoGraphyReset>Reset</StyledTypoGraphyReset></StyledButtonReset>
              <StyledApplyFilterButton data-test-id="applyFilter" onClick={this.collectFilterData}><StyledTypoGraphyApply>Apply Filter</StyledTypoGraphyApply></StyledApplyFilterButton>
            </StyledBoxButtonWrapper>
          </ModaInnerBox>
        </ModalContent>
      </Modal>
    )
  };

  renderTable = () => {
    const { data, orderBy, order, rowsPerPage, page } = this.state;
    const sortedData = this.sortData(data, orderBy, order);
    const paginatedData = this.paginateData(sortedData, page, rowsPerPage);
    return (
      <Box>
        <TableBox>
          <TableContainer component={Paper} style={{ position: "relative" }} className="tableContainer">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tabHeaderCell positionStickyLeft">
                    <Box className="combinedColumn">
                      <TableSortLabel
                        data-test-id="requestSortTestId"
                        active={true}
                        direction={
                          this.state.orderBy === "student_id" ? this.state.order : "asc"
                        }
                        onClick={() => this.handleRequestSort("student_id")}
                      >
                        {configJSON.snoTxt}
                      </TableSortLabel>
                      <TableSortLabel
                        data-test-id="requestSortStudentTestID"
                        active={true}
                        direction={
                          this.state.orderBy === "first_name" ? this.state.order : "asc"
                        }
                        onClick={() => this.handleRequestSort("first_name")}
                      >
                        {configJSON.student}
                      </TableSortLabel>
                    </Box>
                  </TableCell>
                  <TableCell className="tabHeaderCell">

                    {configJSON.passport}
                  </TableCell>
                  <TableCell className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortappTestID"
                      active={true}
                      direction={
                        this.state.orderBy === "activeApplication" ? this.state.order : "asc"
                      }

                      onClick={() => this.handleRequestSort("activeApplication")}
                    >
                      {configJSON.activeApplication}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="associateSortTestID"
                      active={true}
                      direction={
                        this.state.orderBy === "associate_branch_name" ? this.state.order : "asc"
                      } onClick={() => this.handleRequestSort("associate_branch_name")}
                    >
                      {configJSON.associateBranch}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortCreateTestID"
                      active={true}
                      direction={
                        this.state.orderBy === "agent_name" ? this.state.order : "asc"
                      } onClick={() => this.handleRequestSort("agent_name")}
                    >
                      {configJSON.createdBy}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortCourntryTestID"
                      active={true}
                      direction={
                        this.state.orderBy === "country" ? this.state.order : "asc"
                      } onClick={() => this.handleRequestSort("country")}
                    >
                      {configJSON.countryAndState}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortCityTestID"
                      active={true}
                      direction={
                        this.state.orderBy === "city" ? this.state.order : "asc"
                      } onClick={() => this.handleRequestSort("city")}
                    >
                      {configJSON.cityNames}

                    </TableSortLabel>
                  </TableCell>

                  <TableCell className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortStudentAddedTestID"
                      active={true}
                      direction={
                        this.state.orderBy === "created_at" ? this.state.order : "asc"
                      } onClick={() => this.handleRequestSort("created_at")}
                    >
                      {configJSON.dateOfStudentAdded}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className="tabHeaderCell ">
                    <TableSortLabel
                      active={true}
                      data-test-id="requestSortLastActionTestID"
                      direction={
                        this.state.orderBy === "updated_at" ? this.state.order : "asc"
                      } onClick={() => this.handleRequestSort("updated_at")}
                    >
                      {configJSON.dateOfLastAction}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="tabHeaderCell positionStickyRight">
                    <Box>
                    {configJSON.actionTxt}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((item: StudentAttributes,index:number) => (
                  <TableRow key={item?.attributes?.student_id}>
                    <TableCell className="tableInnerCell positionStickyLeft">
                      <Box className="combinedColumnData">
                        <Box>
                          <Typography>{item.renderIndex}</Typography>
                        </Box>
                        <Box className="studentNameDetailsWrapper" data-test-id={"studentNameDetailsWrapper"+ index} onClick={()=>{this.handleViewStudent(item.id)
                          this.handleViewStudentApplication("not")
                        }}>
                          <Typography align="center" className="studentNameDetails">{`${item.attributes.first_name} ${item.attributes.last_name}`}</Typography>
                          <Typography>
                            <Typography component="span" className="studentID">Student ID- </Typography>
                            <Typography component="span" className="studentIDText">{item.attributes.student_id}</Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className="tableInnerCell" >{item?.attributes?.passport_number}</TableCell>
                    <TableCell className="tableInnerCell">0</TableCell>
                    <TableCell className="tableInnerCell">{item?.attributes?.associate_branch_name}</TableCell>
                    <TableCell className="tableInnerCell">{item?.attributes?.agent_name}</TableCell>
                    <TableCell className="tableInnerCell">{`${item?.attributes?.residence_country_full_form} ${item?.attributes?.state}`}</TableCell>
                    <TableCell className="tableInnerCell">{item?.attributes?.city}</TableCell>
                    <TableCell className="tableInnerCell">{moment(item?.attributes?.created_at).format('DD-MMM-YYYY')}</TableCell>
                    <TableCell className="tableInnerCell">{moment(item?.attributes?.updated_at).format('DD-MMM-YYYY')}</TableCell>
                    <TableCell className="positionStickyRight" style={{whiteSpace:'nowrap'}}>
                      <Button onClick={()=>{
                        this.handleViewStudent(item.id)
                        this.handleViewStudentApplication("not")
                      }} data-test-id={"ViewStudent"+ index} className="profileBtn"><PersonOutlineIcon /></Button>
                      <Button onClick={()=>{
                        this.handleViewStudent(item.id)
                        this.handleViewStudentApplication("yes")
                      }} className="viewAppBtn" data-test-id={"viewAppBtn"+ index}>{configJSON.viewApp} </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableBox>
        <PagginationBox >
          <Box />
          <Pagination
            data-test-id="countTestId"
            count={this.renderCount()}
            page={this.state.page}
            onChange={this.handleChangePage}
          />
          <Typography className="showingTxt">
            {this.renderResultText()}
          </Typography>
        </PagginationBox>
      </Box>
    )
  };

  renderNoStudentScreen = () => {
    return <MainBox>
      <Box className="inner">
        <img
          className="accountLogo"
          src={accountOutline}
        />
        <p className="noStudentHeading">{configJSON.noStudentTxt}</p>
        <p className="addStudentDesc">
          {configJSON.addStudentTxtPara}
        </p>
        <button onClick={this.navigateToNewStudentPage} className="addStudentBtn">{configJSON.addStudentBtnTxt}</button>
      </Box>
    </MainBox>
  }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
     <>
     <Loader loading={this.state.isLoading}/>
       <LeadBox>
        <AppHeader isLogin={false}
          data-test-id="appHeaderTestId"
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={true}
          userName={this.state.userDetails?.attributes?.first_name}
          role={this.state.userDetails?.attributes?.role}
          profileImg={this.state.userDetails?.attributes?.image}
        />
        <Box className="testClass">
        <SideMenuBar data-test-id="sidebarNavigationBtn"
          navigationToAnyPage={this.navigationToAnyPage} activeComponent="LeadManagement" />
     {this.state.data.length===0&&!this.state.isLoading ? this.renderNoStudentScreen():
     <Box className="mainBar">
          <BoxButton>
            <Box><StudentTxt data-test-id="studentText">{configJSON.addStudentTxt}</StudentTxt></Box>
            <ButtonBox >
                <Button
                  data-test-id="filterButtonTestID"
                  className={this.state.filteredCount > 0 ? "filterAppliedButton" : "filterBtn"}
                  onClick={this.onFilterClick}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {this.state.filteredCount > 0 ? (
                    <>
                      <Typography className="filterCount">{this.state.filteredCount}</Typography>
                      <Typography className="filterText">Filters Applied</Typography>
                      <SvgIcon
                        component={ClearOutlinedIcon}
                        style={{ color: '#FFF', cursor: 'pointer', marginLeft: 8 }}
                        onClick={this.handleReset}
                      />
                    </>
                  ) : (
                    <>
                      <FilterListOutlinedIcon />
                      {configJSON.filterTxt}
                    </>
                  )}
                </Button>

              <Button
                className="addNewStudentBtn"
                data-test-id="addStudentTestId"
                onClick={this.navigateToNewStudentPage}>
                {configJSON.addStudentTxt}</Button>
            </ButtonBox>
          </BoxButton>

          {this.renderTable()}
          {this.state.filterOpen && this.renderFilter()}
        </Box>
       }
        </Box>
      </LeadBox>
      
     </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const ModalContent = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "24px",
  flexShrink: 0,
  borderRadius: "8px",
  backgroundColor: "#fff",
  width: "90%",
  maxWidth: "448px",
  height: "auto",
  minHeight: "575px",
  boxShadow: "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  margin: "auto",
  "@media (min-width: 600px)": {
    width: "80%",
    maxWidth: "500px",
  },
  "@media (min-width: 960px)": {
    width: "70%",
    maxWidth: "600px",
  },
  "@media (min-width: 1280px)": {
    width: "60%",
    maxWidth: "500px",
  },
});

const ModaInnerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  maxWidth: "400px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  padding: "20px",
  boxSizing: "border-box",
  "& .filterTitle": {
    color: "var(--Basic-Black, #000)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
  },
  "& .insideBox": {
    padding: "16px 30px",
    "& .innerContent": {
      paddingTop: "16px",
      "& input":{
        "@media (max-width: 600px)": {
          paddingTop:'0px'
        },
      }
    },
    "& .innerContent-date":{
      "& input":{
        "@media (max-width: 600px)": {
          paddingTop:'10px !important',
          paddingBottom:'10px !important'
        },
      }
    }
  },
  "& .inputTxt": {
    fontSize: "12px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    color: "#334155"
  },
  "& .selectInput": {
    width: "100%",
    maxWidth: "350px",
    backgroundColor: "#fff",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "40px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  "& .autocompleteField": {
    "& input": {
      marginTop: "10px"
    }
  },
  "@media (min-width: 600px)": {
    width: "90%",
    maxWidth: "400px",
    padding: "30px",
    "& .filterTitle": {
      fontSize: "20px",
      lineHeight: "24px"
    },
    "& .insideBox": {
      padding: "20px 40px",
      "& .innerContent": {
        paddingTop: "20px"
      }
    },
    "& .inputTxt": {
      fontSize: "14px"
    },
    "& .selectInput": {
      height: "45px"
    }
  },
  "@media (min-width: 960px)": {
    width: "80%",
    maxWidth: "400px",
    padding: "40px 0",
    "& .filterTitle": {
      fontSize: "22px"
    },
    "& .insideBox": {
      padding: "20px 50px",
      "& .innerContent": {
        paddingTop: "24px"
      }
    },
    "& .inputTxt": {
      fontSize: "16px"
    },
    "& .selectInput": {
      height: "50px"
    }
  },
  "@media (min-width: 1280px)": {
    width: "70%",
    maxWidth: "400px",
    padding: "50px 0",
    "& .filterTitle": {
      fontSize: "24px",
      lineHeight: "30px"
    },
    "& .insideBox": {
      padding: "20px 0",
      "& .innerContent": {
        paddingTop: "24px"
      }
    },
    "& .inputTxt": {
      fontSize: "18px"
    },
    "& .selectInput": {
      height: "50px"
    }
  }
});

const StudentTxt = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "22px",
  fontWeight: 700
})

const ButtonBox = styled(Box)({
  display: "flex",
  gap: "20px",
  "& .addNewStudentBtn": {
    backgroundColor: "#34D399",
    color: "#FFFFFF",
    borderRadius: "4px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    padding: "6px 10px",
    textTransform: "unset",
    "@media (max-width: 600px)": {
      width: "100%"
    }
  },
  "& .filterBtn": {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    borderRadius: "4px",
    fontWeight: 700,
    textTransform: "unset",
    width: "150px",
    "@media (max-width: 600px)": {
      width: "100%"
    },
  },
  "& .filterAppliedButton":{
    display: 'inline-flex;',
    height: '44px',
    padding: '10px 16px',
    alignItems: 'center',
    gap: '8px',
    flexShrink: 0,
    borderRadius: '8px',
    background: 'var(--Basic-Black, #000)'
  },
  "& .filterCount": {
    display: 'flex',
    width: '22px',
    padding: '0px 5px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '48px',
    background: '#FFF',
    color: '#000',
    fontFamily: "Plus Jakarta Sans",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    marginRight: '10px'
  },
  "& .filterText": {
    color: 'var(--Basic-White, #FFF)',
    fontFamily: "Plus Jakarta Sans",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px'
  },
  "@media (max-width: 600px)": {
    flexDirection: "column",
    width: "100%",
  }
});

const PagginationBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",
  "& .MuiSvgIcon-root": {
    fill: "green"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "unset",
    color: "green",
    borderBottom: "unset !important"
  },
  "& .MuiPaginationItem-page.Mui-selected::before": {
    backgroundColor: "transparent !important",
  },
  "& .MuiPagination-ul .MuiButtonBase-root": {
    position: "relative"
  },
  "& .MuiPagination-ul .MuiButtonBase-root::before": {
    content: "''",
    position: "absolute",
    bottom: "8px",
    left: "50%",
    width: "40%",
    height: "1px",
    backgroundColor: "#000",
    transform: "translateX(-50%)"
  },
  "& .MuiPagination-ul li:first-child .MuiButtonBase-root::before": {
    backgroundColor: "transparent !important",
  },
  "& .MuiPagination-ul li:last-child .MuiButtonBase-root::before": {
    backgroundColor: "transparent !important",
  },

  "& .showingTxt": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    color: "#212121"
  }
});

const TableBox = styled(Box)({
  width: "100%",
  overflowX: "scroll",
  "& .tableContainer": {
    width: "1610px"
  },
  "& .MuiTableContainer-root": {
    overflowX: "unset"
  },
  "& .tabHeaderCell": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "center",
    color: "#0F172A",
    textTransform:"uppercase"
  },
  "& .combinedColumn":{
    width:"100%",
    display:"flex",
    justifyContent: "start",
    gap: "24px",
  },
  "& .combinedColumnData":{
    width:"100%",
    display:"flex",
    justifyContent:"space-between",
    alignItems: "center"
  },
  "& .studentNameDetails":{
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20.16px",
    textAlign: "center",
    color: "#212121",
  },
  "& .studentID":{
    color:"#64748B",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20.16px",
    textAlign: "left",
  },
  "& .studentIDText":{
    color:"#212121",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20.16px",
    textAlign: "left",
  },
  "& .studentNameDetailsWrapper":{
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems:"center",
    padding:"2px",
  },
  "& .tableInnerCell": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "center",
    color: "#0F172A",
  },
  "& .positionStickyLeft": {
    width:"17%",
    position: "sticky",
    left: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    boxShadow: "5px 0 10px rgba(0,0,0,0.1)",
    "@media (max-width: 600px)": {
      position: "inherit",
      boxShadow:"none"
    },
  },
  "& .positionStickyLeft::before": {
    position: "absolute",
    bottom: "-1px",
    left: 0,
    content: "''",
    width: "102%",
    height: "1px",
    backgroundColor: "rgba(224, 224, 224, 1)",
  },
  "& .positionStickyRight": {
    position: "sticky",
    right: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    boxShadow: "-5px 0 10px rgba(0,0,0,0.1)"
  },
  "& .positionStickyRight::before": {
    position: "absolute",
    bottom: "-1px",
    left: 0,
    content: "''",
    width: "102%",
    height: "1px",
    backgroundColor: "rgba(224, 224, 224, 1)"
  },
  "& .viewAppBtn": {
    backgroundColor: "#FFFFFF",
    color: "#34D399",
    borderRadius: "4px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    padding: "5px 8px",
    textTransform: "unset",
    fontSize: "10px",
    border: "1px solid #34D399"
  },
  "& .profileBtn": {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    textTransform: "unset",
    border: "1px solid #475569",
    padding: "0px",
    height: "100%",
    marginRight: "5px",
    "&.MuiButton-root": {
      minWidth: "0px !important"
    }
  }
});

const LeadBox = styled(Box)(({ theme }) => ({
  "& .testClass": {
    display:"flex"
  },
  backgroundColor: "#EEEEEE",
  fontFamily: "Plus Jakarta Sans",
  "& .mainBar": {
    overflowX: 'scroll',
    width:"100%%",
    padding: "28px 50px",
    "@media (max-width: 600px)": {
      padding: "20px"
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "55px", 
      width:"75%" 
    },
  },
}));

const BoxButton = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "center",
    gap: "10px"
  }
})

const StyledBoxButtonWrapper = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "24px",
  "@media (min-width: 600px)": {
    gap: "20px"
  },
  "@media (min-width: 960px)": {
    gap: "16px"
  }
});

const StyledButtonReset = styled(Button)({
  display: "flex",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  border: "1px solid #34D399",
  background: "#D6F6EB",
  "&:hover": {
    background: "#D6F6EB"
  },
  "@media (min-width: 600px)": {
    padding: "8px 14px"
  },
  "@media (min-width: 960px)": {
    padding: "6px 12px"
  }
});

const StyledApplyFilterButton = styled(Button)({
  display: "flex",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "var(--Primary-green, #34D399)",
  "&:hover": {
    backgroundColor: "var(--Primary-green, #34D399) !important"
  },
  "@media (min-width: 600px)": {
    padding: "8px 14px"
  },
  "@media (min-width: 960px)": {
    padding: "6px 12px"
  }
});

const StyledTypoGraphyReset = styled(Typography)({
  color: "var(--Status-Green-600, var(--primary-green, #059669))",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px"
});

const StyledTypoGraphyApply = styled(Typography)({
  color: "var(--Basic-White, var(--0, #FFF))",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px"
});

const StyledCheckBox = styled(Checkbox)({
  borderRadius: "6px",
  border: "1px solid var(--Neutrals-Cool-gray-400, rgba(148, 163, 184, 0.70))",
  width: "20px",
  height: "20px",
  "@media (min-width: 600px)": {
    width: "18px",
    height: "18px"
  },
  "@media (min-width: 960px)": {
    width: "16px",
    height: "16px"
  }
});

const StyledChip = styled(Chip)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "4px",
  backgroundColor: "white !important",
  "& .MuiChip-label": {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    "@media (min-width: 600px)": {
      fontSize: "13px",
      lineHeight: "20px"
    },
    "@media (min-width: 960px)": {
      fontSize: "12px",
      lineHeight: "18px"
    }
  }
});

const BoxPopupClose = styled(Box)({
  display: "flex",
  padding: "4px",
  alignItems: "flex-start",
  gap: "10px",
  position: "absolute",
  right: "16px",
  top: "16px",
  "@media (min-width: 600px)": {
    right: "14px",
    top: "14px"
  },
  "@media (min-width: 960px)": {
    right: "12px",
    top: "12px"
  }
});

const FilterModalTitle = styled(Typography)({
  color: "var(--Basic - Black, #000)",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: '24px'
})

const BoxPopUpTitle = styled(Box)({
  width: "94px",
  height: "32px",
  display: "flex",
  gap: "12px",
  "@media (min-width: 600px)": {
    width: "84px",
    height: "28px",
  },
  "@media (min-width: 960px)": {
    width: "74px",
    height: "24px",
  }
});

const SelectedDateWrapper = styled(Box)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px"
});

const DateWrapper = styled(Box)({
  display: "flex",
  height: "40px",
  padding: "10px 0px",
  justifyContent: "center",
  alignItems: "center",
  gap: "6px"
});

const DateTypoGraphy = styled(Typography)({
  color: "var(--Neutrals-Cool-gray-600, #475569)",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px"
});
const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height:"calc(100dvh - 82px)",
  width:"100%",
  "& .inner": {
    height: "300px",
    textAlign: "center",
  },
  "& .accountLogo": {
    height: "64px",
    padding:"30px",
    background:"#E2E8F0",
    borderRadius:"50%"
  },
  "& .noStudentHeading": {
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: 0,
    color: "#010000",
    letterSpacing: 0.5,
    "@media (max-width: 600px)": {
      fontSize: "22px",
      
    },
  },
  "& .addStudentDesc": {
    color: "#767E87",
  },
  "& .addStudentBtn": {
    color: "white",
    background: "#34DAA0",
    outline: "none",
    border: "none",
    padding: "10px",
    fontWeight: "600",
    borderRadius: "8px",
  },
});

// Customizable Area End