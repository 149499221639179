import React from "react";
// Customizable Area Start
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import VisualAnalyticController, { Props } from "./VisualAnalyticController";
import { Box, CircularProgress, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import ReactECharts from 'echarts-for-react';
import { KeyboardArrowDown } from "@material-ui/icons";
import { EChartsOption } from 'echarts';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DatePicker from "react-datepicker";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class VisualAnalytic extends VisualAnalyticController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderSelect = (
    value: string,
    options: string[],
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void,
    placeholder: string,
    testID:string
  ) => (
    <Select
      className="selectInput"
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
      style={{
        color: value === "" ? "#999" : "#000",
      }}
      value={value}
      onChange={onChange}
      IconComponent={KeyboardArrowDown}
      data-test-id={testID}
    >
      <MenuItem value="">{placeholder}</MenuItem>
      {options.map((item: string) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );

  renderDateRangePicker = (
    startDate: Date | null,
    endDate: Date | null,
    onChange: (dates: [Date, Date]) => void,
    maxDate: Date,
    placeholder: string,
    testID: string
  ) => (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      customInput={<this.CustomInput />}
      dateFormat="dd/MM/yyyy"
      maxDate={maxDate}
      placeholderText={placeholder}
      data-test-id={testID}
      className="selectInput"
    />
  );

  renderChart = (title: string, filters: JSX.Element[], chartOptions: EChartsOption, height: string) => (
    <Box key={title} style={{ width: "100%", marginBottom: "20px" }}>
      <Box className="chartTitle">
        <Typography className="ChartName">{title}</Typography>
        <Box key={title} className="chartFilters">{filters}</Box>
      </Box>
      <Box style={{ width: "100%", height }}>
        <ReactECharts option={chartOptions} style={{ height: "100%", width: "100%" }} />
      </Box>
    </Box>
  );

  renderBarChart = () => {
    const filters = [
      this.renderSelect(
        this.state.applicationFilterYear,
        this.state.globalFilterYear,
        this.handleApplicationYearChange,
        "Choose Year",
        "handleApplicationYearChange"
      ),
      this.renderSelect(
        this.state.applicationFilterCountry,
        this.state.globalFilterCountry,
        this.handleApplicationCountryChange,
        "Choose Country",
        "handleApplicationCountryChange"
      ),
    ];

    return (
      <Grid className="barChart" item sm={12} md={8} xs={12}>
        {this.renderChart("Application statuses", filters, this.state.applicationStatus, "400px")}
      </Grid>
    );
  };

  renderDoughnutChart = () => {
    const filters = [
      this.renderSelect(
        this.state.refundFilterYear,
        this.state.globalFilterYear,
        this.handleRefundYearChange,
        "Choose Year",
        "handleRefundYearChange"
      ),
      this.renderSelect(
        this.state.refundFilterIntake,
        this.state.globalFilterIntakes,
        this.handleRefundIntakeChange,
        "Choose Intake",
        "handleRefundIntakeChange"
      ),
      this.renderSelect(
        this.state.refundFilterCountry,
        this.state.globalFilterCountry,
        this.handleRefundCountryChange,
        "Choose Country",
        "handleRefundCountryChange"
      ),
    ];

    return (
      <Grid className="pieChart" item sm={12} md={4} xs={12}>
        {this.renderChart("Refund Stages", filters, this.state.refundStatus, "400px")}
      </Grid>
    );
  };

  renderLineChart = () => {
    const filters = [
      this.renderSelect(
        this.state.applicationInProcessFilterYear,
        this.state.globalFilterYear,
        this.handleApplicationInProcessYearChange,
        "Choose Year",
        "handleApplicationInProcessYearChange"
      ),
      this.renderSelect(
        this.state.applicationInProcessFilterIntake,
        this.state.globalFilterIntakes,
        this.handleApplicationInProcessIntakeChange,
        "Choose Intake",
        "handleApplicationInProcessIntakeChange"
      ),
      this.renderSelect(
        this.state.applicationInProcessFilterCountry,
        this.state.globalFilterCountry,
        this.handleApplicationInProcessCountryChange,
        "Choose Country",
        "handleApplicationInProcessCountryChange"
      ),
    ];
  
    return (
      <Grid className="lineChart" item sm={12} md={12} xs={12}>
        {this.renderChart(
          "Stages of applications in process",
          filters,
          this.state.applicationStagesProcess,
          "500px"
        )}
      </Grid>
    );
  };

  renderStat(title: string, value: number, percentage: number, addBorder: boolean) {
    return (
      <Grid key={title} item xs={12} sm={3} md={3} style={{ borderRight: addBorder ? "1px solid #e0e0e0" : "none", textAlign: "center", display:"flex", justifyContent:"space-around", flexBasis:"24.50%", maxWidth:"24.50%" }}>
        <Box position="relative">
          <CircularProgress
            variant="determinate"
            value={percentage}
            size={80}
            thickness={4}
            style={{ color: "#000" }}
            color="primary"
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography className="percentage">
              {`${percentage}%`}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography className="description">
            {title}
          </Typography>
          <Typography className="count">
            {value}
          </Typography>
        </Box>
      </Grid>
    );
  }

  renderCircularChart = () => {
    const filters = [
      this.renderSelect(
        this.state.discontinuedFilterYear,
        this.state.globalFilterYear,
        this.discontinuedYearChange,
        "Choose Year",
        "discontinuedYearChange"
      ),
      this.renderSelect(
        this.state.discontinuedFilterCountry,
        this.state.globalFilterIntakes,
        this.discontinuedCountryChange,
        "Choose Intake",
        "discontinuedIntakeChange"
      ),
      this.renderSelect(
        this.state.discontinuedFilterIntake,
        this.state.globalFilterCountry,
        this.discontinuedIntakeChange,
        "Choose Country",
        "discontinuedCountryChange"
      ),
    ];

    return (
        <Grid className="lineChart" item sm={12} md={12} xs={12}>
          <Box className="chartTitle">
            <Typography className="ChartName">Discontinued stage reasons </Typography>
            <Box className="chartFilters">{filters}</Box>
          </Box>

          <Grid className="circularStats" item sm={12} md={12} xs={12}> 
            {this.renderStat("Not Eligible Applications", this.state.discontinuedData.data.attributes.not_eligible.count, this.state.discontinuedData.data.attributes.not_eligible.percentage, true)}
            {this.renderStat("Rejected by University", this.state.discontinuedData.data.attributes.rejected_by_university.count, this.state.discontinuedData.data.attributes.rejected_by_university.percentage, true)}
            {this.renderStat("Confirmed by Agent", this.state.discontinuedData.data.attributes.agent_confirmed_to_discontinue.count, this.state.discontinuedData.data.attributes.agent_confirmed_to_discontinue.percentage, true)}
            {this.renderStat("Agent Non Responsive", this.state.discontinuedData.data.attributes.agent_non_responsive.count, this.state.discontinuedData.data.attributes.agent_non_responsive.percentage, false)}
          </Grid>

          
        </Grid>
    )
  }

  renderAdmissionPerformance = () => {
    const { universityData, orderBy, order } = this.state;
    console.log("universityData ", universityData)
    const sortedData = this.sortData(universityData, orderBy, order);

    const filters = [
      this.renderDateRangePicker(
        this.state.startDate,
        this.state.endDate,
        this.handleDateChange,
        new Date(), 
        "Select a date range",
        "dateRangeId"
      ),
      this.renderSelect(
        this.state.universityFilterUniverSity,
        this.state.globalFilterUniversity,
        this.universityChange,
        "Choose university",
        "universityChange"
      ),
      this.renderSelect(
        this.state.universityFilterCountry,
        this.state.globalFilterCountry,
        this.universityCountryChange,
        "Choose country",
        "universityCountryChange"
      ),
    ];

    return (
      <Grid className="lineChart" item sm={12} md={12} xs={12}>
        <Box className="chartTitle">
          <Typography className="ChartName">Admissions Performance by University</Typography>
          <Box className="chartFilters">{filters}</Box>
        </Box>

        <Grid className="circularStats" item sm={12} md={12} xs={12}>
          <>
            <TablePaper>
              <Table className="table" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        data-test-id="requestSortappTestID"
                      >
                        University
                      </TableSortLabel>
                    </TableCell>
                    <TableCell> <TableSortLabel
                      data-test-id="applicationDataTestid"
                    >
                      Location
                    </TableSortLabel></TableCell>
                    <TableCell><TableSortLabel
                      data-test-id="titleTestID"
                      active={true}
                      direction={
                        this.state.orderBy === "applications_received" ? this.state.order : "asc"
                      }

                      onClick={() => this.handleRequestSort("applications_received")}
                    >
                      Application Received
                    </TableSortLabel></TableCell>
                    <TableCell><TableSortLabel
                      data-test-id="studentSortappTestID"
                      active={true}
                      direction={
                        this.state.orderBy === "days_after_application" ? this.state.order : "asc"
                      }

                      onClick={() => this.handleRequestSort("days_after_application")}
                    >
                      Average Offer Letter Receipt Time
                    </TableSortLabel></TableCell>
                    <TableCell><TableSortLabel
                      data-test-id="universityNameSortappTestID"
                      active={true}
                      direction={
                        this.state.orderBy === "comment_ratio" ? this.state.order : "asc"
                      }

                      onClick={() => this.handleRequestSort("comment_ratio")}
                    >
                      Conversion Ratio
                    </TableSortLabel></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(sortedData) && sortedData.length > 0 ? sortedData.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        <TableCell data-test-id={`manageStaffRowName${index}`}>{row.university_name}</TableCell>
                        <TableCell>{row.location} {row.country}</TableCell>
                        <TableCell>{row.applications_received}</TableCell>
                        <TableCell>{row.days_after_application}</TableCell>
                        <TableCell>{row.comment_ratio}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  )) :
                    <TableRow>
                      <TableCell>
                        <Typography align="center">
                          No Data
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TablePaper>
          </>
        </Grid>


      </Grid>
    )
  }

  CustomInput = ({
    value,
    onClick,
  }: {
    value?: string;
    onClick?: () => void;
  }) => (
    <DateInputBox className="selectInput autocompleteField" onClick={onClick} data-test-id="dateInputId">
      <input type="text" value={value} readOnly placeholder="Choose Period" />
      <CalendarWrapper>
        <CalendarTodayIcon />
      </CalendarWrapper>
    </DateInputBox>
  );

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <AppHeader
          onHomeClick={this.onHomeClick}
          onLoginClick={undefined}
          userName={this.state.userDetails?.attributes?.first_name}
          onSignUpClick={undefined}
          toggleData={this.state.toggleDrawer}
          data-test-id="appHeaderTestId"
          handleToggle={this.handleToggle}
          isUserLogin={true}
          isLogin={false}
          role={this.state.userDetails?.attributes?.role}
          profileImg={this.state.userDetails?.attributes?.image}
        />
        <TaskListBox>
          <SideMenuBar data-test-id="sidebarNavigationBtn"
            navigationToAnyPage={this.navigationToAnyPage} activeComponent="TaskList" />
          <Box className="TaskListBoxWrapper">
            <InterviewBox container spacing={4} style={{ gap: "10px" }}>
              {this.renderBarChart()}
              {this.renderDoughnutChart()}
              {this.renderLineChart()}
              {this.renderCircularChart()}
              {this.renderAdmissionPerformance()}
            </InterviewBox>
          </Box>
        </TaskListBox>
      </ThemeProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyles = {
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalRowViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  modalButtonViewStyle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  tableViewStyle: {
    width: "100%",
    overflow: "hidden",
  },
  tableContainerStyle: {
    maxHeight: 440,
  },
  tableButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  dropdownViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 280,
    justifyContent: "space-between",
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: "auto",
    width: 300,
  },
};


const CalendarWrapper = styled(Box)({
  position: "absolute",
  right: "10px", 
  color: "#757575", 
});

const DateInputBox = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
  padding: "8px 10px",
  width: "210px",
  background:"#fff",
  "& input": {
    border: "none",
    outline: "none",
    width: "100%",
    paddingRight: "30px", 
  },
});


const TablePaper = styled(Paper)({
  width: "100%",
  overflowX: "auto",
  "& .table": {
    minWidth: 650,
  },
  "& .tableCellCentered": {
    display: "flex",
    alignItems: "center",
  },
  "& .borderedSpan": {
    border: "1px solid #23CF93",
    whiteSpace: "nowrap",
    marginRight: "2px",
    marginLeft: "2px",
    color: "#34D399"
  },
  "& .pending": {
    background: "#FEF3C7",
    color: "#D97706",
    padding: "8px",
    borderRadius: "40px",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "12px"
  },
  "& .approved": {
    background: "#D1FAE5",
    color: "#059669",
    padding: "8px",
    borderRadius: "40px",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "12px"
  },
  "& .under_review": {
    background: "#F1F5F9",
    color: "#475569",
    padding: "8px",
    borderRadius: "40px",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "12px",
  },
  "& .fix_required": {
    background: "#FEE2E2",
    color: "#DC2626",
    padding: "8px",
    borderRadius: "40px",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "12px"
  },
  "& .statusInactive": {
    background: "#FFE1DF",
    color: "#DB5460",
    padding: "8px",
    borderRadius: "10px",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "12px"
  },
  "& .MuiTableCell-head": {
    fontWeight: 600
  },
  "& .MuiTableCell-root": {
    textAlign: "center",
    justifyContent: "center"
  },
  "& .tableCellExpanded": {
    background: "#F9FAFC",
  },
  "& .expandMore": {
    verticalAlign: "middle",
  },
  "& .dueDate": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center"
  },
  "& .expiry": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "center",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#000000",
    backgroundColor: "#F1F5F9",
    padding: "8px",
    borderRadius: "8px"
  }
});

const TaskListBox = styled(Box)({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  },
  fontFamily: "Plus Jakarta Sans",
  "& .TaskListBoxWrapper": {
    backgroundColor: "#F7F7F7",
    width: "100%",
    padding: "28px 50px",
    "@media (max-width: 600px)": {
      padding: "20px",
      marginLeft: "64px",
    },
  },
});

const InterviewBox = styled(Grid)({
  "@media (max-width: 600px)": {
    flexDirection: "column"
  },
  "& .barChart": {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    gap: "16px",
    flexBasis: "58% !important",
    maxWidth: "58% !important",
    borderRadius: "16px",
    "@media (max-width: 600px)": {
      flexBasis: "100% !important",
      maxWidth: "100% !important",
    },
  },
  "& .chartTitle": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .chartFilters": {
    display: "flex",
    justifyContent: "space-between",
    gap: "24px"
  },
  "& .ChartName": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A",
  },
  "& .pieChart": {
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    gap: "16px",
    flexBasis: "41% !important",
    maxWidth: "41% !important",
    "@media (max-width: 600px)": {
      flexBasis: "100% !important",
      maxWidth: "100% !important",
    },
  },
  "& .lineChart": {
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    gap: "16px",
    flexBasis: "100% !important",
    maxWidth: "100% !important",
    "@media (max-width: 600px)": {
      flexBasis: "100% !important",
      maxWidth: "100% !important",
    },
  },
  "& .circularStats": {
    borderRadius: "16px",
    display: "flex",
    backgroundColor: "#FFFFFF",
    gap: "16px",
    flexBasis: "100% !important",
    maxWidth: "100% !important",
    "@media (max-width: 600px)": {
      flexBasis: "100% !important",
      maxWidth: "100% !important",
    },
  },
  "& .selectInput": {
    width: "100%",
    maxWidth: "350px",
    backgroundColor: "#fff",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "40px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  "& .percentage":{
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color:"#0F172A"
  },
  "& .description": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#64748B",
  },
  "& .count":{
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A",
  }
})


// Customizable Area End