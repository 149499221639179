Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";

exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.getProfileEndPoint = "account_block/accounts/";
exports.filterYear = "bx_block_visual_analytics/year_filter"
exports.filterIntake = "bx_block_visual_analytics/intake_filter"
exports.filterCountry = "bx_block_visual_analytics/country_filter"
exports.application_status_report = "bx_block_visual_analytics/application_status_report"
exports.refund_stage_report = "bx_block_visual_analytics/refund_stage_report"
exports.in_progress_report = "bx_block_visual_analytics/in_progress_report"
exports.discontinued_stages = "bx_block_visual_analytics/statistics/discontinued_stages"
exports.admission_performance = "bx_block_visual_analytics/statistics/admission_performance"
exports.select_university = "bx_block_visual_analytics/statistics/select_university"
exports.statistics = "bx_block_visual_analytics/statistics"
exports.admission_performance_filter = "bx_block_visual_analytics/statistics/admission_performance_filter"
// Customizable Area End